import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'

import { httpOk, POST, PUT, GET } from 'helpers/api'
import { formatDate, regexNumeric, regexPhoneNumber, formatMoney } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)

	async function searchWithFilter() {
		var filterUrl = "/organizations"

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	async function createUpdate(e) {
		e.preventDefault()
		var sendData = { ...selectedItem }
		sendData.phone1 = '998' + regexNumeric(sendData.phone1)
		if (sendData.phone2)
			sendData.phone2 = '998' + regexNumeric(sendData.phone2)

		var response;
		if (sendData.id) {
			response = await PUT('/organizations', sendData, { loader: true })
		} else {
			response = await POST('/organizations', sendData, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	function toggleModal(bool, item) {
		if (bool) {
			var itemCopy = { ...item }
			if (itemCopy.organization_phone)
				itemCopy.organization_phone = itemCopy.organization_phone.substring(3, 12)
			if (itemCopy.director_phone)
				itemCopy.director_phone = itemCopy.director_phone.substring(3, 12)
			if (itemCopy.accountant_phone)
				itemCopy.accountant_phone = itemCopy.accountant_phone.substring(3, 12)

			setSelectedItem(itemCopy)
			setShowModal(true)
		} else {
			setShowModal(false)
		}

	}

	useEffect(() => {
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('organizations')} [{formatMoney(data?.total)}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }} className="td-ellipsis">{t('legal_name')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis">{t('tin')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis">{t('organization_phone')}</th>
									<th style={{ 'width': '10%' }} className="text-center">
										{t('cheques')}
									</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-center">{t('last_activity')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-center">{t('registered')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-center">{t('note')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">
											<CustomTooltip text={item.id}>
												{item.row_number}
											</CustomTooltip>
										</td>
										<td>{item.name}</td>
										<td>{item.legal_name}</td>
										<td>{item.tin}</td>
										<td className="text-nowrap">{regexPhoneNumber(item.organization_phone)}</td>
										<td className="text-center">{item.count_cheques}</td>
										<td className="text-center">{formatDate(item.cheque_closed_at)}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td>{item.note}</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal
				show={showModal}
				animation={false}
				dialogClassName="update-modal-width"
				centered
				backdrop="static"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('edit')}</>
							:
							<>{t('create')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('tin')}</label>
							<input type="number" className="form-control" name="tin"
								value={selectedItem.tin ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'tin': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('bank_account')}</label>
							<input type="number" className="form-control" name="checking_account"
								value={selectedItem.checking_account ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'checking_account': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('organization_phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control"
									value={selectedItem.organization_phone ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'organization_phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('director_phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control"
									value={selectedItem.director_phone ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'director_phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('accountant_phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control"
									onChange={(e) => setSelectedItem({ ...selectedItem, 'accountant_phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('address')}</label>
							<input type="text" className="form-control" name="address"
								value={selectedItem.address ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'address': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={selectedItem.note ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						{selectedItem.id &&
							<>
								{selectedItem.external_pos_name &&
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('external_pos')}</p>
										<p>{selectedItem.external_pos_name}</p>
									</div>
								}

								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('documents')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="auto_create_documents"
											checked={selectedItem?.auto_create_documents ?? false}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'auto_create_documents': e.target.checked })} />
									</div>
								</div>
							</>
						}
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100" onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
