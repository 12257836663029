import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { httpOk, POST } from 'helpers/api'

function Index({
	products,
	modalTemplateCRUD,
	setModalTemplateCRUD,
	toggleModalTemplateCRUD,
}) {
	const { t } = useTranslation()

	const [items, setItems] = useState([])
	const [itemsDuplicate, setItemsDuplicate] = useState([])

	async function createUpdate() {
		const response = await POST('/documents-templates', {
			'name': modalTemplateCRUD.name,
			'items': modalTemplateCRUD.products,
		}, { loader: true })

		if (httpOk(response)) {
			setModalTemplateCRUD({ 'bool': false, 'name': "", 'items': [] })
		}
	}

	function addAllToList() {
		var modalTemplateCRUDCopy = JSON.parse(JSON.stringify(modalTemplateCRUD))
		var itemsCopy = [...items]

		modalTemplateCRUDCopy.products = itemsCopy
		itemsCopy = []

		setItems(itemsCopy)
		setItemsDuplicate(itemsCopy)
		setModalTemplateCRUD(modalTemplateCRUDCopy)
	}

	function deleteAllItem() {
		var modalTemplateCRUDCopy = JSON.parse(JSON.stringify(modalTemplateCRUD))
		var itemsCopy = [...items]

		itemsCopy = modalTemplateCRUDCopy.products
		modalTemplateCRUDCopy.products = []

		setItems(itemsCopy)
		setItemsDuplicate(itemsCopy)
		setModalTemplateCRUD(modalTemplateCRUDCopy)
	}

	function addToList(index) {
		var modalTemplateCRUDCopy = JSON.parse(JSON.stringify(modalTemplateCRUD))
		var itemsCopy = [...items]

		modalTemplateCRUDCopy.products.push(itemsCopy[index])
		itemsCopy.splice(index, 1);

		setItems(itemsCopy)
		setItemsDuplicate(itemsCopy)
		setModalTemplateCRUD(modalTemplateCRUDCopy)
	}

	function deleteItem(index) {
		var modalTemplateCRUDCopy = JSON.parse(JSON.stringify(modalTemplateCRUD))
		var itemsCopy = [...items]

		itemsCopy.push(modalTemplateCRUDCopy.products[index])
		modalTemplateCRUDCopy.products.splice(index, 1);

		itemsCopy.sort((a, b) => a.product_id - b.product_id);

		setItems(itemsCopy)
		setItemsDuplicate(itemsCopy)
		setModalTemplateCRUD(modalTemplateCRUDCopy)
	}

	function search(value) {
		if (value === '') {
			setItems(itemsDuplicate)
			return;
		}

		var itemsDuplicateCopy = [...itemsDuplicate]
		var result = itemsDuplicateCopy.filter(item => item?.name?.toString().toLowerCase().includes(value.toLowerCase()))

		setItems(result)
	}

	useEffect(() => {
		setItems([...products])
		setItemsDuplicate([...products])
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={modalTemplateCRUD.bool} animation={false}
				backdrop="static" size="lg" centered
				onHide={() => toggleModalTemplateCRUD(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('products')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div className="form-group">
							<input type="text" className="form-control"
								name="name"
								autoComplete="off"
								placeholder={t('template')}
								value={modalTemplateCRUD.name}
								onChange={(e) => setModalTemplateCRUD({ ...modalTemplateCRUD, 'name': e.target.value })} />
						</div>
						<div className="d-flex gap-3">
							<div className="table-responsive w-100"
								style={{ 'height': '500px', 'overflow': 'auto' }}>
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
											<th style={{ 'width': '60%' }} className="td-input">
												<input type="text" placeholder={`${t('search')}...`}
													onChange={(e) => search(e.target.value)} />
											</th>
											<th style={{ 'width': '40%' }}>{t('type')}</th>
											<th style={{ 'minWidth': '33px' }}>
												<div className="d-flex justify-content-end">
													<div className="table-action-button table-action-primary-button"
														onClick={() => addAllToList()}>
														<i className="uil uil-angle-double-right"></i>
													</div>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										{items?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td className="td-ellipsis">{item.name}</td>
												<td className="td-ellipsis">{item.product_type_name}</td>
												<td>
													<div className="d-flex justify-content-end">
														<div className="table-action-button table-action-primary-button"
															onClick={() => addToList(index)}>
															<i className="uil uil-angle-right-b"></i>
														</div>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<div className="table-responsive w-100"
								style={{ 'height': '500px', 'overflow': 'auto' }}>
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
											<th style={{ 'width': '60%' }}>{t('name2')}</th>
											<th style={{ 'width': '40%' }}>{t('type')}</th>
											<th style={{ 'minWidth': '33px' }}>
												<div className="d-flex justify-content-end">
													<div className="table-action-button table-action-danger-button"
														onClick={() => deleteAllItem()}>
														<i className="uil uil-times"></i>
													</div>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										{modalTemplateCRUD?.products?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td className="td-ellipsis">{item.name}</td>
												<td className="td-ellipsis">{item.product_type_name}</td>
												<td>
													<div className="d-flex justify-content-end">
														<div className="table-action-button table-action-danger-button"
															onClick={() => deleteItem(index)}>
															<i className="uil uil-times"></i>
														</div>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex gap-2 w-100">
						<button type="button" className="btn btn-warning w-100"
							onClick={() => toggleModalTemplateCRUD(false)}>
							{t('cancel')}
						</button>
						<button type="button" className="btn btn-primary w-100"
							onClick={() => createUpdate()}>
							{t('create')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default Index
