import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { findKeyFromArrayByValue, returnSign } from "helpers/helpers";
import { httpOk, GET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const hotkeys = [
		{ 'id': 'F1', 'name': 'F1' },
		{ 'id': 'F2', 'name': 'F2' },
		{ 'id': 'F3', 'name': 'F3' },
		{ 'id': 'F4', 'name': 'F4' },
		{ 'id': 'F5', 'name': 'F5' },
		{ 'id': 'F6', 'name': 'F6' },
		{ 'id': 'F7', 'name': 'F7' },
		{ 'id': 'F8', 'name': 'F8' },
		{ 'id': 'F9', 'name': 'F9' },
		{ 'id': 'F10', 'name': 'F10' },
	]
	const [ptc, setPTC] = useState([])
	const [pti, setPTI] = useState([])
	const [poses, setPoses] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: reduxAccount.pos_id,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/payment-types',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterUrl = "/payment-types"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/payment-types${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	function handleChangePos(e, item, index) {
		var selectedItemCopy = { ...selectedItem }

		selectedItemCopy['poses'][index]['status'] = e.target.checked
		setSelectedItem(selectedItemCopy)
	}

	function resetFilter() {
		setFilterData({
			pos_id: reduxAccount.pos_id,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function createUpdate() {
		var response;
		if (selectedItem.payment_type_id) {
			response = await PUT('/payment-types', selectedItem, { loader: true })
		} else {
			response = await POST('/payment-types', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	async function toggleModal(bool = false, item = { 'show_in_cashbox': true }) {
		if (bool) {
			var itemCopy = { ...item }
			itemCopy.poses = poses
			itemCopy.poses = itemCopy.poses.map(pos => ({
				...pos,
				'status': true
			}));

			setSelectedItem(itemCopy)
			setShowModal(bool)
			return
		}

		setSelectedItem({})
		setShowModal(bool)
	}

	function handleChangeIntegrationKey(e) {
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.integration_keys[e.target.name] = e.target.value

		setSelectedItem(selectedItemCopy)
	};

	async function getPTC() {
		const response = await GET('/helper/payment-types-categories')
		if (httpOk(response)) setPTC(response.data)
	}

	async function getPTI() {
		const response = await GET('/helper/payment-types-integrations')
		if (httpOk(response)) setPTI(response.data)
	}

	async function getPoses() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPTC()
		getPTI()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('payment_types')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '10%' }}>{t('name2')}</th>
									<th style={{ 'width': '15%' }}>{t('type')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('fiscalization')}</th>
									<th style={{ 'width': '15%' }} className="td-ellipsis text-center"
										title={t('exact_amount')}>
										{t('exact_amount')}
									</th>
									<th style={{ 'width': '15%' }} className="td-ellipsis text-center"
										title={t('cash_drawer')}>
										{t('cash_drawer')}
									</th>
									<th style={{ 'width': '15%' }} className="td-ellipsis text-center"
										title={t('hotkeys')}>
										{t('hotkeys')}
									</th>
									<th style={{ 'width': '15%' }} className="td-ellipsis text-center"
										title={t('show_in_cashbox')}>
										{t('show_in_cashbox')}
									</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td className="td-ellipsis">{item.name}</td>
										<td className="td-ellipsis">{item.type_category_name}</td>
										<td className="text-center">
											{item.fiscal ?
												<span>{t('yes')}</span>
												:
												<span>{t('no')}</span>
											}
										</td>
										<td className="text-center">
											{item.exact_amount ?
												<span>{t('yes')}</span>
												:
												<span>{t('no')}</span>
											}
										</td>
										<td className="text-center">
											{item.cash_drawer ?
												<span>{t('yes')}</span>
												:
												<span>{t('no')}</span>
											}
										</td>
										<td className="text-center">{item.hotkey}</td>
										<td className="text-center">
											{item.show_in_cashbox ?
												<span>{t('yes')}</span>
												:
												<span>{t('no')}</span>
											}
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} size="lg"
				centered backdrop="static" onHide={() => toggleModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex gap-3">
						<div className="w-50">
							<form autoComplete="off">
								{!selectedItem.payment_type_id &&
									<>
										<div className="form-group">
											<label>{t('type')}<span className="required-mark">*</span></label>
											<Select
												isDisabled={selectedItem.id}
												options={ptc}
												value={ptc.find(option => option.id === selectedItem.payment_type_category_id)}
												onChange={(option) => setSelectedItem({ ...selectedItem, 'payment_type_category_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</>
								}

								<div className="form-group">
									<label>{t('name2')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="name"
										value={selectedItem.name ?? ''}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('name_in_cheque')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="name_in_cheque"
										value={selectedItem.name_in_cheque ?? ''}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'name_in_cheque': e.target.value })} />
								</div>

								<div className="d-flex gap-2">
									<div className="form-group w-100">
										<label>{t('hotkeys')}</label>
										<Select
											isClearable
											options={hotkeys}
											value={hotkeys.find(option => option.id === selectedItem.hotkey)}
											onChange={(option) => setSelectedItem({ ...selectedItem, 'hotkey': option ? option.id : null })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group w-100">
										<label>{t('order2')}</label>
										<input type="text" className="form-control" name="order_number"
											value={selectedItem.order_number ?? ''}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'order_number': e.target.value })} />
									</div>
								</div>

								{selectedItem.payment_type_category_id === 5 &&
									<>
										<div className="form-group">
											<label>{t('payment_system')}<span className="required-mark">*</span></label>
											<Select
												options={pti}
												value={pti.find(option => option.id === selectedItem.payment_type_integration_id)}
												onChange={(option) => setSelectedItem({
													...selectedItem,
													'payment_type_integration_id': option.id,
													'integration_keys': option.integration_keys ?? {},
												})}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>

										{(selectedItem?.integration_keys && Object.keys(selectedItem?.integration_keys).length > 0) &&
											<>
												{Object.keys(selectedItem?.integration_keys).map((key) => (
													<div className="form-group w-100" key={key}>
														<label>{key}</label>
														<input type="text" className="form-control" name={key}
															value={selectedItem?.integration_keys[key] ?? ''}
															onChange={handleChangeIntegrationKey} />
													</div>
												))}
											</>
										}
									</>
								}

								<div className="form-check">
									<input id="fiscal" className="form-check-input" type="checkbox"
										checked={selectedItem?.fiscal ?? false}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'fiscal': e.target.checked })}
									/>
									<label className="form-check-label" htmlFor="fiscal">
										{t('fiscalization')}
									</label>
								</div>

								<div className="form-check">
									<input id="exact_amount" className="form-check-input" type="checkbox"
										checked={selectedItem?.exact_amount ?? false}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'exact_amount': e.target.checked })}
									/>
									<label className="form-check-label" htmlFor="exact_amount">
										{t('exact_amount')}
									</label>
								</div>

								<div className="form-check">
									<input id="cash_drawer" className="form-check-input" type="checkbox"
										checked={selectedItem?.cash_drawer ?? false}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'cash_drawer': e.target.checked })}
									/>
									<label className="form-check-label" htmlFor="cash_drawer">
										{t('cash_drawer')}
									</label>
								</div>

								<div className="form-check">
									<input id="show_in_cashbox" className="form-check-input" type="checkbox"
										checked={selectedItem?.show_in_cashbox ?? false}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'show_in_cashbox': e.target.checked })}
									/>
									<label className="form-check-label" htmlFor="show_in_cashbox">
										{t('show_in_cashbox')}
									</label>
								</div>

								{selectedItem.payment_type_category_id === 5 &&
									<>
										<div className="form-check">
											<input id="show_qr" className="form-check-input" type="checkbox"
												checked={selectedItem?.show_qr ?? false}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'show_qr': e.target.checked })}
											/>
											<label className="form-check-label" htmlFor="show_qr">
												{t('show_qr')}
											</label>
										</div>
									</>
								}
							</form>
						</div>
						<div className="w-50">
							<label>{t('poses')}</label>
							<div className="table-responsive">
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
											<th style={{ 'width': '90%' }}>{t('name2')}</th>
											<th style={{ 'width': '10%' }}></th>
										</tr>
									</thead>
									<tbody>
										{selectedItem?.poses?.map((item, index) => (
											<tr key={index}>
												<td style={{ 'width': '33px' }} className="text-center">{index + 1}</td>
												<td>{item.name}</td>
												<td className="d-flex justify-content-end">
													<div className="form-check form-switch form-switch-sm">
														<input type="checkbox" className="form-check-input"
															checked={item.status ? true : false}
															onChange={(e) => handleChangePos(e, item, index)}
														/>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="d-flex gap-2 w-100 mt-3">
						<button type="button" className="btn btn-outline-warning w-100"
							onClick={() => toggleModal()}>
							{t('cancel')}
						</button>
						<button type="button" className="btn btn-primary w-100"
							onClick={() => createUpdate()}>
							{t('save')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index