import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import { httpOk, GET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [deliveryRuleTypes, setDeliveryRuleTypes] = useState([])
	const [externalCouriers, setExternalCouriers] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})

	async function searchWithFilter() {
		var filterUrl = "/chain/delivery-rules"

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (selectedItem.id) {
			response = await PUT('/chain/delivery-rules', selectedItem, { loader: true })
		} else {
			response = await POST('/chain/delivery-rules', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	function handleSettingChange(e, type) {
		var selectedItemCopy = { ...selectedItem }

		if (type === 'text') {
			selectedItemCopy[e.target.name] = e.target.value
		}

		if (type === 'number') {
			selectedItemCopy[e.target.name] = e.target.value.replace(/[^0-9.]/g, '')
		}

		setSelectedItem(selectedItemCopy)
	}

	function handleChangeRule(e, index) {
		var selectedItemCopy = { ...selectedItem }

		selectedItemCopy['options'][index][e.target.name] = e.target.value.replace(/[^0-9.]/g, '')
		setSelectedItem(selectedItemCopy)
	}

	function addDeliveryRuleOption() {
		var selectedItemCopy = { ...selectedItem }

		if (!selectedItemCopy.hasOwnProperty('options')) {
			selectedItemCopy['options'] = []
		}

		selectedItemCopy['options'].push({
			'from_distance': '',
			'to_distance': '',
			'from_price': '',
			'to_price': '',
			'delivery_price': ''
		})
		setSelectedItem(selectedItemCopy)
	}

	function deleteDeliveryCostRule(index) {
		var selectedItemCopy = { ...selectedItem }

		selectedItemCopy['options'].splice(index, 1)
		setSelectedItem(selectedItemCopy)
	}

	function handleSelectDeliveryRule(option) {
		setSelectedItem({ ...selectedItem, 'delivery_rule_type_id': option ? option.id : null })
	}

	function toggleModal(bool = false, item = {}) {
		setSelectedItem(item)
		setShowModal(bool)
	}

	async function getDeliveryRuleTypes() {
		const response = await GET(`/helper/delivery-rules-types`)
		if (httpOk(response)) setDeliveryRuleTypes(response.data)
	}

	async function getExternalCouriers() {
		const response = await GET(`/helper/external-couriers`)
		if (httpOk(response)) setExternalCouriers(response.data)
	}

	useEffect(() => {
		searchWithFilter()
		getDeliveryRuleTypes()
		getExternalCouriers()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('delivery_rules')} [{data.length}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }}>{t('type')}</th>
									<th style={{ 'width': '60%' }}></th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td>{item.delivery_rule_name}</td>
										<td></td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				size="md" backdrop="static"
				onHide={() => toggleModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}</label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ''}
								autoFocus
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group w-100">
							<label>{t('delivery_type')}<span className="required-mark">*</span></label>
							<Select
								options={deliveryRuleTypes}
								value={deliveryRuleTypes.find(option => option.id === selectedItem.delivery_rule_type_id)}
								onChange={(option) => handleSelectDeliveryRule(option)}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						{Number(selectedItem.delivery_rule_type_id) === 1 &&
							<div className="form-group">
								<label>{t('delivery_price')}</label>
								<input type="text" className="form-control" name="delivery_price"
									value={selectedItem.delivery_price ?? ''}
									autoFocus
									onChange={(e) => setSelectedItem({ ...selectedItem, 'delivery_price': e.target.value })} />
							</div>
						}
						{Number(selectedItem.delivery_rule_type_id) === 2 &&
							<div className="form-group">
								<label>{t('delivery_price')}</label>
								<input type="text" className="form-control" name="delivery_price"
									value={selectedItem.delivery_price ?? ''}
									autoFocus
									onChange={(e) => setSelectedItem({ ...selectedItem, 'delivery_price': e.target.value })} />
							</div>
						}
						{Number(selectedItem.delivery_rule_type_id) === 3 &&
							<>
								<div className="table-responsive">
									<table className="table table-bordered mb-0">
										<thead>
											<tr>
												<th style={{ 'width': '33.3%' }}>{t('from_m')}</th>
												<th style={{ 'width': '33.3%' }}>{t('to_m')}</th>
												<th style={{ 'width': '33.3%' }}>{t('delivery_price')}</th>
												<th style={{ 'minWidth': '33px' }}>
													<div className="d-flex justify-content-center">
														<div className="d-flex justify-content-center">
															<div className="table-action-button table-action-success-button"
																onClick={() => addDeliveryRuleOption()}>
																<i className="uil-plus"></i>
															</div>
														</div>
													</div>
												</th>
											</tr>
										</thead>
										<tbody>
											{selectedItem.options?.map((item, index) => (
												<tr key={index}>
													<td className="td-input">
														<input type="number" pattern="[0-9]*" className="form-control"
															name="from_distance"
															value={item.from_distance ?? ''}
															onChange={(e) => handleChangeRule(e, index)} />
													</td>
													<td className="td-input">
														<input type="number" pattern="[0-9]*" className="form-control"
															name="to_distance"
															value={item.to_distance ?? ''}
															onChange={(e) => handleChangeRule(e, index)} />
													</td>
													<td className="td-input">
														<input type="number" pattern="[0-9]*" className="form-control"
															name="delivery_price"
															value={item.delivery_price ?? ''}
															onChange={(e) => handleChangeRule(e, index)} />
													</td>
													<td>
														<div className="d-flex justify-content-center">
															<div className="d-flex justify-content-center">
																<div className="table-action-button table-action-danger-button"
																	onClick={() => deleteDeliveryCostRule(index)}>
																	<i className="uil-times"></i>
																</div>
															</div>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</>
						}
						{Number(selectedItem.delivery_rule_type_id) === 4 &&
							<>
								<div className="table-responsive">
									<table className="table table-bordered mb-0">
										<thead>
											<tr>
												<th style={{ 'width': '33.3%' }}>{t('from_amount')}</th>
												<th style={{ 'width': '33.3%' }}>{t('to_amount')}</th>
												<th style={{ 'width': '33.3%' }}>{t('delivery_price')}</th>
												<th style={{ 'minWidth': '33px' }}>
													<div className="d-flex justify-content-center">
														<div className="d-flex justify-content-center">
															<div className="table-action-button table-action-success-button"
																onClick={() => addDeliveryRuleOption()}>
																<i className="uil-plus"></i>
															</div>
														</div>
													</div>
												</th>
											</tr>
										</thead>
										<tbody>
											{selectedItem.options?.map((item, index) => (
												<tr key={index}>
													<td className="td-input">
														<input type="number" pattern="[0-9]*" className="form-control"
															name="from_price"
															value={item.from_price ?? ''}
															onChange={(e) => handleChangeRule(e, index)} />
													</td>
													<td className="td-input">
														<input type="number" pattern="[0-9]*" className="form-control"
															name="to_price"
															value={item.to_price ?? ''}
															onChange={(e) => handleChangeRule(e, index)} />
													</td>
													<td className="td-input">
														<input type="number" pattern="[0-9]*" className="form-control"
															name="delivery_price"
															value={item.delivery_price ?? ''}
															onChange={(e) => handleChangeRule(e, index)} />
													</td>
													<td>
														<div className="d-flex justify-content-center">
															<div className="d-flex justify-content-center">
																<div className="table-action-button table-action-danger-button"
																	onClick={() => deleteDeliveryCostRule(index)}>
																	<i className="uil-times"></i>
																</div>
															</div>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</>
						}
						{Number(selectedItem.delivery_rule_type_id) === 5 &&
							<>
								<div className="d-flex gap-2">
									<div className="form-group w-100">
										<label>{t('fixed_m')}</label>
										<input type="text" className="form-control" name="delivery_fixed_distance"
											value={selectedItem.delivery_fixed_distance ?? ''}
											autoFocus
											onChange={(e) => handleSettingChange(e, 'number')} />
									</div>
									<div className="form-group w-100">
										<label>{t('fixed_amount')}</label>
										<input type="text" className="form-control" name="delivery_fixed_price"
											value={selectedItem.delivery_fixed_price ?? ''}
											autoFocus
											onChange={(e) => handleSettingChange(e, 'number')} />
									</div>
								</div>
								<div className="d-flex gap-2">
									<div className="form-group w-100 d-none">
										<label>{t('overlimit_m')}</label>
										<input type="text" className="form-control" name="delivery_price"
											value={selectedItem.delivery_price ?? ''}
											autoFocus
											onChange={(e) => handleSettingChange(e, 'number')} />
									</div>
									<div className="form-group w-100">
										<label>{t('overlimit_amount')}</label>
										<input type="text" className="form-control" name="delivery_price"
											value={selectedItem.delivery_price ?? ''}
											autoFocus
											onChange={(e) => handleSettingChange(e, 'number')} />
									</div>
								</div>
							</>
						}

						{Number(selectedItem.delivery_rule_type_id) === 6 &&
							<div className="form-group w-100">
								<label>{t('external_couriers')}<span className="required-mark">*</span></label>
								<Select
									isClearable
									options={externalCouriers}
									value={externalCouriers.find(option => option.id === selectedItem.external_courier_id)}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'external_courier_id': option ? option.id : null })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						}

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal()}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index