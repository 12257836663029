import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { httpOk, GET, POST } from 'helpers/api'
import { formatMoney, formatDate } from 'helpers/helpers'
import imgCashbox from 'assets/images/cashbox.png'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState([])

	async function openShift(item) {
		var sendData = {
			'cashier_login': reduxAccount.login,
			'pos_id': item.pos_id,
			'cashbox_id': item.id,
		}

		const response = await POST('/shift-open', sendData, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	async function getData() {
		const response = await GET('/cashbox', {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('cashboxes')}
				</h4>
			</div>

			<div className="row">
				{data?.map((item, index) => (
					<div className="col-md-4 mb-3" key={index}>
						<div className="card">
							<div className="card-body">
								<div className="d-flex justify-content-center mb-2">
									<img width="100" height="100"
										src={imgCashbox}
										alt="react" />
								</div>
								<div className="d-flex justify-content-between">
									<div className="fw-600">{t('cashbox')}:</div>
									<div className="text-end">
										{(item.shift && item?.shift?.closed_at == null) &&
											<span className="text-success">({t('open')})</span>
										}
										{item?.shift?.closed_at &&
											<span className="text-danger">({t('closed')})</span>
										}
									</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="fw-600">{t('status')}:</div>
									<div className="text-end">
										{item.name}
									</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="fw-600">{t('pos')}:</div>
									<div className="text-end">{item?.pos_name}</div>
								</div>
								{item?.shift?.opened_at ?
									<div className="d-flex justify-content-between">
										<div className="fw-600">{t('shift_opening_date')}:</div>
										<div className="text-truncate">{formatDate(item?.shift?.opened_at)}</div>
									</div>
									:
									<div className="d-flex justify-content-between">
										<div className="fw-600">{t('shift_opening_date')}:</div>
										<div className="text-truncate">{formatDate(item?.shift?.closed_at)}</div>
									</div>
								}
								<div className="d-flex justify-content-between">
									<div className="fw-600">{t('cashier')}:</div>
									<div>{item?.shift?.cashier_name}</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<div className="fw-600">{t('balance')}:</div>
									<div className="text-truncate">
										{formatMoney(item?.shift?.balance)}
									</div>
								</div>
								<button className="btn btn-primary w-100"
									onClick={() => openShift(item.id)}>
									{t('enter')}
								</button>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	)
}

export default Index
