import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import DeleteModal from 'pages/parts/DeleteModal'
import { returnSign, findKeyFromArrayByValue } from "helpers/helpers";
import { GET, POST, PUT, DELETE, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const reduxAccount = useSelector(state => state.account)

	const types = [
		{ 'id': 'hall', 'name': t('hall') },
		{ 'id': 'preparation_place', 'name': t('preparation_places') },
	]
	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: reduxAccount.pos_id,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/halls',
		response: null
	})

	async function searchWithFilter() {
		var filterUrl = "/halls"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/halls${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (selectedItem.id) {
			response = await PUT('/halls', selectedItem, { loader: true })
		} else {
			response = await POST('/halls', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function toggleModal(bool = false, item = {}) {
		item.pos_id = reduxAccount.pos_id
		setSelectedItem(item)
		setShowModal(bool)
	}

	async function deleteItem() {
		const response = await DELETE('/halls', { 'id': deleteModalParams.id }, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			setModalDelete(false)
		}
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('halls')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter()}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }}>{t('pos')}</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }}>{t('type')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('tables')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('products')}</th>
									<th style={{ 'width': '35%' }}></th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td>{item.name}</td>
										<td>
											{item.type === 'hall' ?
												<span>{t('hall')}</span>
												:
												<span>{t('preparation_places')}</span>
											}
										</td>
										<td className="text-end">{item.count_tables}</td>
										<td className="text-end">{item.count_products}</td>
										<td></td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														<div className="table-dropdown-item" onClick={() => navigate('/halls/scheme/' + item.id)}>
															<i className="uil uil-sitemap"></i>
															{t('scheme')}
														</div>
														{item.status > 0 &&
															<div className="table-dropdown-item text-danger"
																onClick={() => {
																	setDeleteModalParams({ id: item.id, index: index });
																	setModalDelete(true)
																}}>
																<i className="uil-times"></i>
																{t('delete')}
															</div>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => toggleModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}</label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name}
								autoFocus
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>

						{!selectedItem.id &&
							<>
								<div className="form-group">
									<label>{t('poses')}</label>
									<Select
										isDisabled={selectedItem.id}
										options={poses}
										value={poses.find(option => option.id === selectedItem.pos_id)}
										onChange={(option) => setSelectedItem({ ...selectedItem, 'pos_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('type')}</label>
									<Select
										isDisabled={selectedItem.id}
										options={types}
										value={types.find(option => option.id === selectedItem.type)}
										onChange={(option) => setSelectedItem({ ...selectedItem, 'type': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</>
						}

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal()}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index