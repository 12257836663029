export let exportMenu = [
	{
		icon: "sidebar-icon uil-chart-growth",
		children: [
			{
				title: true,
				i18n: "statistics",
			},
			{
				url: "/dashboard-main",
				i18n: "home",
				icon: "uil-chart-growth",
				permission: "analysis_main",
				active: false,
			},
			{
				url: "/dashboard-cheques",
				i18n: "check_analysis",
				icon: "uil-invoice",
				permission: "analysis_cheques",
				active: false,
			},
			{
				url: "/dashboard-expenses",
				i18n: "expense_analysis",
				icon: "uil-coins",
				permission: "analysis_expenses",
				active: false,
			},
			{
				url: "/dashboard-elements",
				i18n: "element_analysis",
				icon: "uil-cell",
				permission: "analysis_elements",
				active: false,
			},
			{
				title: true,
				i18n: "debit_credit",
			},
			{
				url: "/report-suppliers",
				i18n: "suppliers",
				icon: "uil-chart-pie-alt",
				permission: "report_suppliers",
				active: false,
			},
			{
				url: "/report-clients",
				i18n: "clients",
				icon: "uil-chart-pie-alt",
				permission: "report_clients",
				active: false,
			},
			{
				url: "/report-employees",
				i18n: "employees",
				icon: "uil-chart-pie-alt",
				permission: "report_clients",
				active: false,
			},
			{
				url: "/report-organizations",
				i18n: "organizations",
				icon: "uil-chart-pie-alt",
				permission: "report_organizations",
				active: false,
			},
			{
				title: true,
				i18n: "reports",
			},
			{
				url: "/cheques-all",
				i18n: "cheques",
				icon: "uil-chart-pie-alt",
				permission: "report_cheques",
				active: false,
			},
			{
				url: "/cheques-rating",
				i18n: "check_evaluation",
				icon: "uil-chart-pie-alt",
				permission: "report_cheques_ratings",
				active: false,
			},
			{
				url: "/cheques-by-clients",
				i18n: "client_checks",
				icon: "uil-chart-pie-alt",
				permission: "report_clients_cheques",
				active: false,
			},
			{
				url: "/report-incomes",
				i18n: "good_reception",
				icon: "uil-chart-pie-alt",
				permission: "report_documents_in",
				active: false,
			},
			{
				url: "/report-write-off",
				i18n: "write_off",
				icon: "uil-chart-pie-alt",
				permission: "report_write_off",
				active: false,
			},
			{
				url: "/report-balance",
				i18n: "balance2",
				icon: "uil-chart-pie-alt",
				permission: "report_balance",
				active: false,
			},
			{
				url: "/report-calculation",
				i18n: "calculation",
				icon: "uil-chart-pie-alt",
				permission: "report_calculation",
				active: false,
			},
			{
				url: "/report-agents",
				i18n: "agents",
				icon: "uil-chart-pie-alt",
				permission: "report_agents",
				active: false,
			},
			{
				url: "/report-employee",
				i18n: "employees",
				icon: "uil-chart-pie-alt",
				permission: "report_employees",
				active: false,
			},
			{
				url: "/report-menu",
				i18n: "menu",
				icon: "uil-chart-pie-alt",
				permission: "report_menu",
				active: false,
			},
			{
				url: "/report-menu2",
				i18n: "menu2",
				icon: "uil-chart-pie-alt",
				permission: "report_menu2",
				active: false,
			},
			{
				url: "/report-expenses",
				i18n: "expenses",
				icon: "uil-chart-pie-alt",
				permission: "report_expenses",
				active: false,
			},
			{
				url: "/report-safe",
				i18n: "safe",
				icon: "uil-chart-pie-alt",
				permission: "report_safe",
				active: false,
			},
			{
				url: "/report-bank",
				i18n: "checking_account",
				icon: "uil-chart-pie-alt",
				permission: "report_bank",
				active: false,
			},
			{
				url: "/report-sales",
				i18n: "sales",
				icon: "uil-chart-pie-alt",
				permission: "report_sales",
				active: false,
			},
			{
				url: "/report-gross-income",
				i18n: "gross_income",
				icon: "uil-chart-pie-alt",
				permission: "report_gross_income",
				active: false,
			},
			{
				url: "/report-cheques-summary",
				i18n: "summary_cheques",
				icon: "uil-chart-pie-alt",
				permission: "report_summary_cheques",
				active: false,
			},
			{
				url: "/report-summary",
				i18n: "summary_report",
				icon: "uil-chart-pie-alt",
				permission: "report_summary",
				active: false,
			},
			{
				url: "/report-z",
				i18n: "z_report",
				icon: "uil-chart-pie-alt",
				permission: "report_summary",
				active: false,
			},
			{
				url: "/report-check-point",
				i18n: "check_point",
				icon: "uil-chart-pie-alt",
				permission: "report_check_point",
				active: false,
			},
			{
				url: "/report-profit-loses",
				i18n: "P&L",
				icon: "uil-chart-pie-alt",
				permission: "report_profit_loses",
				active: false,
			},
		]
	},
	{
		icon: "uil-clipboard-notes sidebar-icon",
		children: [
			{
				title: true,
				i18n: "documents",
			},
			{
				url: "/documents-in",
				i18n: "good_reception",
				icon: "uil-bring-front",
				permission: "documents_in",
				active: false,
			},
			{
				url: "/documents-return",
				i18n: "return_supplier",
				icon: "uil-bring-front",
				permission: "documents_in",
				active: false,
			},
			{
				url: "/inventory-wms",
				i18n: "inventory",
				icon: "uil-calculator-alt",
				permission: "documents_inventory",
				active: false,
			},
			{
				url: "/write-off",
				i18n: "write_off",
				icon: "uil-trash-alt",
				permission: "documents_write_off",
				active: false,
			},
			{
				url: "/documents-preparation",
				i18n: "preparation",
				icon: "uil-apps",
				permission: "documents_preparation",
				active: false,
			},
			{
				url: "/documents-orders",
				i18n: "orders",
				icon: "uil-clipboard-notes",
				permission: "documents_orders",
				active: false,
			},
			{
				title: true,
				i18n: "transfer",
			},
			{
				url: "/wms-transfer-wms",
				i18n: "transfer_between_wms",
				icon: "uil-truck",
				permission: "documents_internal_transfer",
				active: false,
			},
			{
				url: "/wms-transfer-branch",
				i18n: "transfer_between_branches",
				icon: "uil-truck",
				permission: "documents_internal_transfer",
				active: false,
			},
			{
				url: "/wms-transfer-branch/documents",
				i18n: "receipt_from_branch",
				icon: "uil-import",
				permission: "external_documents_in",
				active: false,
			},
			{
				title: true,
				i18n: "services",
			},
			{
				url: "/service/wms-transfer-pos",
				i18n: "moving_another_store",
				icon: "uil-share",
				permission: "external_documents_transfer",
				active: false,
			},
			{
				url: "/service/idocuments-in",
				i18n: "receipt_from_supplier",
				icon: "uil-import",
				permission: "external_documents_in",
				active: false,
			},
			{
				url: "/service/idocuments-orders",
				i18n: "orders_another_pos",
				icon: "uil-clipboard-notes",
				permission: "external_documents_orders",
				active: false,
			},
		],
	},
	{
		icon: "uil-setting sidebar-icon",
		children: [
			{
				title: true,
				i18n: "settings",
			},
			{
				url: "/pos",
				i18n: "poses",
				icon: "uil-shop",
				permission: "poses",
				active: false,
			},
			{
				url: "/cashbox",
				i18n: "cashboxes",
				icon: "uil-store",
				permission: "cashboxes",
				active: false,
			},
			{
				url: "/halls",
				i18n: "halls",
				icon: "uil-circle",
				permission: "halls",
				active: false,
			},
			{
				url: "/tables",
				i18n: "tables",
				icon: "uil-lamp",
				permission: "tables",
				active: false,
			},
			{
				url: "/menu",
				i18n: "menu",
				icon: "uil-list-ui-alt",
				permission: "menu",
				active: false,
			},
			{
				url: "/categories",
				i18n: "categories",
				icon: "uil-web-section-alt",
				permission: "categories",
				active: false,
			},
			{
				url: "/elements",
				i18n: "elements",
				icon: "uil-cell",
				permission: "elements",
				active: false,
			},
			{
				url: "/products",
				i18n: "products",
				icon: "uil-shopping-cart",
				permission: "products",
				active: false,
			},
			{
				url: "/price-change",
				i18n: "price_change",
				icon: "uil-redo",
				permission: "price_change",
				active: false,
			},
			{
				url: "/order-types-subs",
				i18n: "order_types",
				icon: "uil-transaction",
				permission: "order_types",
				active: false,
			},
			{
				url: "/payment-types",
				i18n: "payment_types",
				icon: "uil-transaction",
				permission: "payment_types",
				active: false,
			},
			{
				url: "/product-order",
				i18n: "order2",
				icon: "uil-sort-amount-up",
				permission: "product_order",
				active: false,
			},
			{
				url: "/comments",
				i18n: "comments",
				icon: "uil-comment",
				permission: "comments",
				active: false,
			},
			{
				url: "/suppliers",
				i18n: "suppliers",
				icon: "uil-truck-loading",
				permission: "suppliers",
				active: false,
			},
			{
				url: "/organizations",
				i18n: "organizations",
				icon: "uil-university",
				permission: "organizations",
				active: false,
			},
			{
				url: "/payment-pupose-types",
				i18n: "income_expense",
				icon: "uil-coins",
				permission: "expenses",
				active: false,
			},
			{
				url: "/expense-plans",
				i18n: "expense_plan",
				icon: "uil-coins",
				permission: "expense_plans",
				active: false,
			},
			{
				url: "/deletion-reasons",
				i18n: "deletion_reasons",
				icon: "uil-clipboard-alt",
				active: false,
			},
			{
				url: "/wms-all",
				i18n: "warehouses",
				icon: "uil-docker",
				permission: "warehouses",
				active: false,
			},
			{
				url: "/tax-categories",
				i18n: "tax_categories",
				icon: "uil-web-section-alt",
				active: false,
			},
			{
				url: "/service-rating-templates",
				i18n: "service_rating_templates",
				icon: "uil-star",
				permission: "service_rating_templates",
				active: false,
			},
			{
				title: true,
				i18n: "discounts",
			},
			{
				url: "/loyalty/services",
				i18n: "loyalty",
				icon: "uil-trophy",
				permission: "loyalty_services",
				active: false,
			},
			{
				url: "/discount/services",
				i18n: "discounts",
				icon: "uil-percentage",
				permission: "discount_services",
				active: false,
			},
			{
				url: "/discount/promo-codes",
				i18n: "promocodes",
				icon: "uil-gift",
				permission: "discount_promo_codes",
				active: false,
			},
		]
	},
	{
		icon: "uil-user-square sidebar-icon",
		children: [
			{
				title: true,
				i18n: "staff",
			},
			{
				url: "/employees",
				i18n: "staff",
				icon: "uil-user-square",
				permission: "employees",
				active: false,
			},
			{
				url: "/employee-positions",
				i18n: "positions",
				icon: "uil-award-alt",
				permission: "employees_positions",
				active: false,
			},
			{
				url: "/employee-bonus",
				i18n: "KPI",
				icon: "uil-favorite",
				permission: "employees_bonuses",
				active: false,
			},
			{
				url: "/employee-salary",
				i18n: "salary",
				icon: "uil-abacus",
				permission: "employees_salaries",
				active: false,
			},
			{
				url: "/clients",
				i18n: "clients",
				icon: "uil-users-alt",
				permission: "clients",
				active: false,
			},
			{
				url: "/clients-cards",
				i18n: "clients_cards",
				icon: "uil-credit-card-search",
				permission: "clients_cards",
				active: false,
			},
			{
				url: "/agents",
				i18n: "agents",
				icon: "uil-user-md",
				permission: "agents",
				active: false,
			},
			// {
			// 	url: "/online-users",
			// 	i18n: "online_users",
			// 	icon: "uil-laptop-cloud",
			// 	active: false,
			// },
			{
				url: "/synchronizations",
				i18n: "synchronizations",
				icon: "uil-laptop-cloud",
				active: false,
			},
		]
	},
	{
		icon: "uil-bell sidebar-icon",
		children: [
			{
				title: true,
				i18n: "notification",
			},
			{
				url: "/telegram-sending",
				i18n: "telegram_notifications",
				icon: "uil-telegram",
				permission: "sending_telegram",
				active: false,
			},
			{
				url: "/sms-sending",
				i18n: "sms_sending",
				icon: "uil-comment-alt-lines",
				permission: "sending_sms",
				active: false,
			},
			{
				url: "/sms-templates",
				i18n: "sms_templates",
				icon: "uil-comment-alt-lines",
				permission: "sms_templates",
				active: false,
			},
		]
	},
	{
		icon: "uil-panel-add",
		children: [
			{
				title: true,
				i18n: "chain_report",
			},
			{
				url: "/chain/balance",
				i18n: "balance",
				icon: "uil-panel-add",
				permission: "chain_balance",
				active: false,
			},
			{
				url: "/chain/element-incomes",
				i18n: "elements_incomes",
				icon: "uil-panel-add",
				permission: "chain_sales_elements",
				active: false,
			},
			{
				url: "/chain/element-sales",
				i18n: "elements_sales",
				icon: "uil-panel-add",
				permission: "chain_sales_elements",
				active: false,
			},
			{
				url: "/chain/product-sales",
				i18n: "products_sales",
				icon: "uil-panel-add",
				permission: "chain_sales_products",
				active: false,
			},
			{
				url: "/chain/external-transfer",
				i18n: "moving_another_store",
				icon: "uil-panel-add",
				permission: "chain_external_transfer",
				active: false,
			},
			{
				url: "/chain/external-transfer-summary",
				i18n: "moving_another_store_amount",
				icon: "uil-panel-add",
				permission: "chain_external_transfer",
				active: false,
			},
			{
				url: "/chain/report-hours",
				i18n: "cheques",
				icon: "uil-panel-add",
				permission: "chain_cheques",
				active: false,
			},
			{
				url: "/chain/report-sales",
				i18n: "sales",
				icon: "uil-panel-add",
				permission: "chain_sales",
				active: false,
			},
			{
				url: "/chain/report-plan-fact",
				i18n: "plan_fact",
				icon: "uil-panel-add",
				permission: "chain_plan_fact",
				active: false,
			},
			{
				url: "/chain/report-cheque-rating",
				i18n: "check_evaluation",
				icon: "uil-panel-add",
				permission: "chain_cheques_ratings",
				active: false,
			},
			{
				url: "/chain/report-service-rating",
				i18n: "service_evaluation",
				icon: "uil-panel-add",
				permission: "chain_service_ratings",
				active: false,
			},
			{
				url: "/chain/report-courier-tracking",
				i18n: "courier_tracking",
				icon: "uil-panel-add",
				permission: "chain_courier_tracking",
				active: false,
			},
		]
	},
	{
		icon: "uil-cloud",
		children: [
			{
				title: true,
				i18n: "external_services",
			},
			{
				url: "external-integrations",
				i18n: "integrations",
				icon: "uil-data-sharing",
				active: false,
			},
			{
				url: "/external-menu",
				i18n: "external_menu",
				icon: "uil-arrow-compress-h",
				active: false,
			},
			{
				url: "/external-telegram-bots",
				i18n: "telegram_bot",
				icon: "uil-robot",
				active: false,
			},
			{
				url: "/external-couriers",
				i18n: "external_couriers",
				icon: "uil-car-sideview",
				active: false,
			},
			{
				url: "/external-call-center",
				i18n: "call_center",
				icon: "uil-headphones-alt",
				active: false,
			},
			{
				url: "/external-pos-orders",
				i18n: "delivery",
				icon: "uil uil-truck",
				active: false,
			},
			{
				url: "/external-delivery-rules",
				i18n: "delivery_rules",
				icon: "uil-truck",
				active: false,
			},
		]
	},
]