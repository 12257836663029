import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const usePreventExit = (isFormDirty) => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (isFormDirty) {
				event.preventDefault();
				event.returnValue = "Данные будут потеряны без сохранения. Вы уверены?";
			}
		};

		const handlePopState = (event) => {
			if (isFormDirty) {
				const confirmLeave = window.confirm("Данные будут потеряны без сохранения. Выйти?");
				if (!confirmLeave) {
					// Отменяем действие "Назад"
					window.history.pushState(null, "", location.pathname);
					event.preventDefault();
				} else {
					navigate(-1); // Разрешаем переход назад
				}
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);
		window.addEventListener("popstate", handlePopState);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
			window.removeEventListener("popstate", handlePopState);
		};
	}, [isFormDirty, navigate, location.pathname]);

	return (to) => {
		if (!isFormDirty || window.confirm("Данные будут потеряны. Выйти?")) {
			navigate(to);
		}
	};
};

export default usePreventExit;
