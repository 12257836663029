import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import { GET, PUT, httpOk } from 'helpers/api'
import { Dropdown, DropdownButton } from "react-bootstrap"
import { formatDate } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [data, setData] = useState([])

	async function handleChangeActive(e, type, index, id) {
		//var dataCopy = JSON.parse(JSON.stringify(data))

		var sendDta = {
			"id": id,
			"active": e.target.checked,
		}

		await PUT('/discount/services-active', sendDta, { loader: true })
		getData()
	}

	function goTo(page) {
		if (page === '/discount/services/create-dish') {
			navigate('/discount/services/create-dish')
			return
		}
		if (page === '/discount/services/create-manual') {
			navigate('/discount/services/create-manual')
			return
		}
		if (page === '/discount/services/create-cheque') {
			navigate('/discount/services/create-cheque')
			return
		}
		if (page === '/discount/services/create-first-order') {
			navigate('/discount/services/create-first-order')
			return
		}
	}

	async function getData() {
		const response = await GET('/discount/services', {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('discount')} [{data.length}]
				</h4>
				<div className="d-flex">
					<DropdownButton title={t('action')}>
						<Dropdown.Item onClick={() => goTo('/discount/services/create-dish')}>
							{t('discount_automatic_dish')}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => goTo('/discount/services/create-manual')}>
							{t('discount_manual_cheque')}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => goTo('/discount/services/create-cheque')}>
							{t('discount_automatic_cheque')}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => goTo('/discount/services/create-first-order')}>
							{t('discount_automatic_first_order')}
						</Dropdown.Item>
					</DropdownButton>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '25%' }}>{t('name2')}</th>
									<th style={{ 'width': '35%' }}>{t('type')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('launch_date')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('stop_date')}</th>
									<th style={{ 'width': '10%' }} className="text-center text-nowrap">{t('status')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td>{item.service_name}</td>
										<td className="text-center">{formatDate(item.start_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-center">{formatDate(item.stop_at, 'dd.MM.yy HH:mm')}</td>
										<td>
											<div className="form-check form-switch form-switch-md justify-content-center">
												<input type="checkbox" className="form-check-input" name="active"
													checked={item.active}
													onChange={(e) => handleChangeActive(e, 'checkbox', index, item.id)} />
											</div>
										</td>
										<td>
											{item.discount_type === 2 &&
												<div className="d-flex justify-content-end">
													<Link to={`/discount/services/update-dish/${item.id}`} className="text-white">
														<div className="table-action-button table-action-primary-button">
															<i className="uil-edit-alt"></i>
														</div>
													</Link>
												</div>
											}
											{item.discount_type === 3 &&
												<div className="d-flex justify-content-end">
													<Link to={`/discount/services/update-saving/${item.id}`} className="text-white">
														<div className="table-action-button table-action-primary-button">
															<i className="uil-edit-alt"></i>
														</div>
													</Link>
												</div>
											}
											{item.discount_type === 4 &&
												<div className="d-flex justify-content-end">
													<Link to={`/discount/services/create-manual/${item.id}`} className="text-white">
														<div className="table-action-button table-action-primary-button">
															<i className="uil-edit-alt"></i>
														</div>
													</Link>
												</div>
											}
											{item.discount_type === 5 &&
												<div className="d-flex justify-content-end">
													<Link to={`/discount/services/update-first-order/${item.id}`} className="text-white">
														<div className="table-action-button table-action-primary-button">
															<i className="uil-edit-alt"></i>
														</div>
													</Link>
												</div>
											}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index