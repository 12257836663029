import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown, Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, findKeyFromArrayByValue, formatDate } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const date = new Date()
	const reduxAccount = useSelector(state => state.account)

	const orderTypes = [
		{ "id": 1, "name": t('hall') },
		{ "id": 2, "name": t('takeaway') },
		{ "id": 3, "name": t('delivery') },
	]

	const [poses, setPoses] = useState([])
	const [orderSubTypes, setOrderSubTypes] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		'start_date': formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		'end_date': formatDate(new Date(), 'yyyy-MM-dd'),
		'pos_id': reduxAccount.pos_id,
		'search': '',
	})
	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState([])
	const [modalDetail, setModalDetail] = useState(false)

	async function toggleModal(item, type) {
		if (type === 'detail') {
			var filterDataCopy = { ...filterData }
			var filterUrl = `/report-menu-detail/${item.product_id}`
			var urlParams = ""

			if (filterDataCopy.pos_id)
				urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
			if (filterDataCopy.order_type)
				urlParams += returnSign(urlParams) + 'order_type=' + filterDataCopy.order_type
			if (filterDataCopy.order_type_sub_id)
				urlParams += returnSign(urlParams) + 'order_type_sub_id=' + filterDataCopy.order_type_sub_id
			if (filterDataCopy.shift_id)
				urlParams += returnSign(urlParams) + 'shift_id=' + filterDataCopy.shift_id
			if (filterDataCopy.start_date)
				urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
			if (filterDataCopy.end_date)
				urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
			if (filterDataCopy.search)
				urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search

			const response = await GET(filterUrl + urlParams, {}, { loader: true })
			if (httpOk(response)) {
				setSelectedItem(response.data)
				setModalDetail(true)
			}
		}
	}

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = "/report-menu"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.order_type)
			urlParams += returnSign(urlParams) + 'order_type=' + filterData.order_type
		if (filterData.order_type_sub_id)
			urlParams += returnSign(urlParams) + 'order_type_sub_id=' + filterData.order_type_sub_id
		if (filterData.shift_id)
			urlParams += returnSign(urlParams) + 'shift_id=' + filterData.shift_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/report-menu${urlParams}` !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams
		if (exportToExcel) {
			var excelHeaders = []
			excelHeaders.push([t('menu'), t('name2'), t('sale_price'), t('quantity'), t('sale_amount'), t('gross_income')])
			for (let i = 0; i < data.data.length; i++) {
				for (let j = 0; j < data.data[i]['products'].length; j++) {
					var excelItems = []
					excelItems.push(data.data[i]['menu_name'])
					excelItems.push(data.data[i]['products'][j]['name'])
					excelItems.push(formatMoney(data.data[i]['products'][j]['total_sale_price'] / data.data[i]['products'][j]['total_quantity']))
					excelItems.push(data.data[i]['products'][j]['total_quantity'])
					excelItems.push(data.data[i]['products'][j]['total_sale_price'])
					excelItems.push(data.data[i]['products'][j]['total_income'])
					excelHeaders.push(excelItems)
				}
			}

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('menu') + ".xlsx");
		}

		if (!exportToExcel) {
			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setData(response.data)
			}
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await GET(
			"/report-menu",
			{
				start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
				end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			},
			{ loader: true }
		)
		if (httpOk(response)) {
			setData(response.data.data)
		}
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getOrderSubTypes() {
		const response = await GET('/helper/order-type-subs')
		if (httpOk(response)) setOrderSubTypes(response.data)
	}

	useEffect(() => {
		getPoses()
		getOrderSubTypes()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('menu')} [{data?.data?.length}]
				</h4>
			</div>

			<div className="card mb-3">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('shift')} ID</label>
												<input type="text" className="form-control"
													onChange={(e) => setFilterData({ ...filterData, 'shift_id': e.target.value })} />
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('order_type')}</label>
												<Select
													options={orderTypes}
													value={orderTypes.find(option => option.id === filterData.order_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_type': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('delivery_type')}</label>
												<Select
													isClearable
													options={orderSubTypes}
													value={orderSubTypes.find(option => option.id === filterData.order_type_sub_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_type_sub_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2"
										onClick={() => searchWithFilter(true)}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
						<div className="vertical-center">
							<h5 className="text-end">
								<b>{t('sale_amount')}</b>: {formatMoney(data.total_amount)} <br />
								<b>{t('gross_income')}</b>: {formatMoney(data.total_income)}
							</h5>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{(filterData.order_type) &&
									<span className="me-2">
										<b>{t('order_type')}: </b>
										{findKeyFromArrayByValue(orderTypes, 'id', filterData.order_type, 'name')}
									</span>
								}
								{(filterData.orderSubTypes) &&
									<span className="me-2">
										<b>{t('delivery_type')}: </b>
										{findKeyFromArrayByValue(orderSubTypes, 'id', filterData.order_type_sub_id, 'name')}
									</span>
								}
								{filterData.shift_id &&
									<span className="me-2">
										<b>{t('shift')} ID: </b> {filterData.shift_id}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}
				</div>
			</div>

			{data?.data?.map((item, index) => (
				<div className="card mb-3" key={index}>
					<div className="card-body">
						<div key={index}>
							<div className="table-responsive">
								<table className="table table-bordered table-hover">
									<thead>
										<tr>
											<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
											<th style={{ 'width': '40%' }}>{item.menu_name}</th>
											<th className="text-end" style={{ 'width': '15%' }}>{t('sale_price')}</th>
											<th className="text-end" style={{ 'width': '15%' }}>{t('quantity')}</th>
											<th className="text-end" style={{ 'width': '15%' }}>{t('sale_amount')}</th>
											<th className="text-end" style={{ 'width': '15%' }}>{t('income')}</th>
										</tr>
									</thead>
									<tbody>
										{item.products.map((product, index2) => (
											<tr key={index2}
												className={(
													selectedItem &&
													selectedItem.product_id === product.product_id
												) ? 'table-tr-active' : ''}
												onClick={() => toggleModal(product, 'detail')}>
												<td className="text-center">{index2 + 1}</td>
												<td>{product.name}</td>
												<td className="text-end">{formatMoney(product.total_sale_price / product.total_quantity)}</td>
												<td className="text-end">{formatMoney(product.total_quantity, 2)}</td>
												<td className="text-end">{formatMoney(product.total_sale_price)}</td>
												<td className="text-end">{formatMoney(product.total_income)}</td>
											</tr>
										))}
										<tr>
											<td></td>
											<td><b>{(t('total'))}</b></td>
											<td></td>
											<td className="text-end"><b>{formatMoney(item.total_quantity)}</b></td>
											<td className="text-end"><b>{formatMoney(item.total_sale_price)}</b></td>
											<td className="text-end"><b>{formatMoney(item.total_income)}</b></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			))}

			<Modal show={modalDetail} animation={false} size="lg" centered onHide={() => setModalDetail(false)}>
				<Modal.Body>
					<h3 className="mb-3">{selectedItem.first_name}</h3>
					<div className="table-responsive inventory-modal-height">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '25%' }}>{t('name2')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('quantity')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('price')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('sale_price')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('sale_amount')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('income')}</th>
								</tr>
							</thead>
							<tbody>
								{selectedItem?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-end">{formatMoney(item.total_quantity)}</td>
										<td className="text-end">{formatMoney(item.price)}</td>
										<td className="text-end">{formatMoney(item.sale_price)}</td>
										<td className="text-end">{formatMoney(item.total_sale_price)}</td>
										<td className="text-end">{formatMoney(item.total_income)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Index