import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { httpOk, FILE } from 'helpers/api'
import { formatMoney } from 'helpers/helpers'

function Index({
	showRightBar,
	setShowRightBar,
	toggleModalProduct,
	selectedItem,
	setSelectedItem,
	updateProduct
}) {
	const { t } = useTranslation()

	const units = [
		{ 'id': 'г', 'name': t('gramm') },
		{ 'id': 'мл', 'name': t('mililitr') },
	]

	async function fileUpload(e) {
		const file = e.target.files[0];

		if (file) {
			const imgSize = file.size / (1024 * 1024);

			if (imgSize > 25) { // Проверка, если размер больше 25 МБ
				toast.error("Размер файла не должен превышать 25 МБ.");
				return;
			}

			// Используем промис для обработки загрузки изображения
			const validateImage = () => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = (event) => {
						const img = new Image();
						img.onload = () => {
							const imgWidth = img.width;
							const imgHeight = img.height;

							if (imgWidth < 800 || imgHeight < 600) {
								reject(new Error("Минимальный размер 800x600 px"));
							} else {
								resolve();
							}
						};
						img.onerror = () => reject(new Error("Failed to load image"));
						img.src = event.target.result;
					};
					reader.onerror = () => reject(new Error("Failed to read file"));
					reader.readAsDataURL(file);
				});
			};

			try {
				// Ждем завершения валидации изображения
				await validateImage();

				// Если прошла валидация, формируем данные и отправляем запрос
				let formData = new FormData();
				formData.append("id", selectedItem.id);
				formData.append("image", file);
				formData.append("db_name", 'external_menu_products');
				formData.append("column_name", 'image');
				formData.append("path", 'products');

				let response;
				if (!selectedItem.image) {
					response = await FILE('/upload-image', formData, {}, { loader: true });
				} else {
					response = await FILE(`/update-image`, formData, {}, { loader: true });
				}

				if (httpOk(response)) {
					setSelectedItem({ ...selectedItem, 'image': response.data.url });
				}
			} catch (error) {
				toast.error(error.message);
			}
		}
	}

	useEffect(() => {
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{showRightBar &&
				<>
					<div className="right-sidebar-background"
						onClick={() => setShowRightBar(!showRightBar)}>
					</div>

					<div className="right-sidebar">
						<div className="position-relative">
							<div className="rightbar-close-icon"
								onClick={() => setShowRightBar(!showRightBar)}>
								<i className="uil uil-times"></i>
							</div>
						</div>

						<div className="p-2">
							<h3 className="mb-3">{t('product')}</h3>
							<form onSubmit={updateProduct} autoComplete="off">
								<div className="form-group">
									<label>{t('name2')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="name"
										value={selectedItem.name}
										autoFocus
										onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
								</div>

								<div className="form-group">
									<label>{t("description")}</label>
									<textarea className="form-control" rows="5"
										value={selectedItem.description ?? ''}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'description': e.target.value })}>
									</textarea>
									<span>{t("letters")} - {formatMoney(selectedItem?.description?.length)}|500</span>
								</div>

								<div className="d-flex gap-2">
									<div>
										<div className="form-group w-100">
											<label>{t('sale_price')}<span className="required-mark">*</span></label>
											<input type="text" className="form-control" name="sale_price"
												value={selectedItem.sale_price}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'sale_price': e.target.value.replace(/[^0-9.]/g, '') })} />
										</div>
										<div className="form-group w-100">
											<label>{t('weight')}</label>
											<input type="text" className="form-control" name="weight"
												value={selectedItem.weight}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'weight': e.target.value.replace(/[^0-9.]/g, '') })} />
										</div>
										<div className="form-group w-100">
											<label>{t('unit')}</label>
											<Select
												isClearable
												options={units}
												value={units.find(option => option.id === selectedItem.unit) || ''}
												onChange={(option) => setSelectedItem({ ...selectedItem, 'unit': option ? option.id : null })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>

									<div className="con-upload" style={{ 'width': '300px' }}>
										<div className="con-img-upload">
											{selectedItem.image &&
												<div className="img-upload">
													<button type="button" className="btn-x-file"
														onClick={() => setSelectedItem({ ...selectedItem, 'image': '' })}>
														<i className="uil-times"></i>
													</button>
													<img src={`https://backend.mison.uz/uploads/products/${selectedItem.image}`}
														style={{ maxWidth: 'none', maxHeight: '100%' }} alt={selectedItem.image} />
												</div>
											}
											{!selectedItem.image &&
												<div className="con-input-upload">
													<input type="file" onChange={(e) => fileUpload(e)} />
													<span className="text-input fz14">
														{t('image2')}
													</span>
												</div>
											}
										</div>
									</div>
								</div>

								<h6 className="mt-3">{t('energy_value_per')}</h6>
								<div className="d-flex gap-2">
									<div className="form-group w-100">
										<label>{t('proteins')}</label>
										<input type="number" className="form-control" name="proteins"
											disabled
											value={selectedItem.proteins ?? ''}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'proteins': e.target.value })} />
									</div>
									<div className="form-group w-100">
										<label>{t('fat')}</label>
										<input type="number" className="form-control" name="fat"
											disabled
											value={selectedItem.fat ?? ''}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'fat': e.target.value })} />
									</div>
									<div className="form-group w-100">
										<label>{t('carbohydrates')}</label>
										<input type="number" className="form-control" name="carbohydrates"
											disabled
											value={selectedItem.carbohydrates ?? ''}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'carbohydrates': e.target.value })} />
									</div>
									<div className="form-group w-100">
										<label>{t('calories')}</label>
										<input type="number" className="form-control" name="calories"
											disabled
											value={selectedItem.calories ?? ''}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'calories': e.target.value })} />
									</div>
								</div>

								<div className="d-flex justify-content-between">
									<p className="fw-600 vertical-center">{t('on_sale')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="status"
											checked={selectedItem?.status}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'status': e.target.checked })}
										/>
									</div>
								</div>

								<div className="d-flex gap-2 w-100 mt-3">
									<button type="button" className="btn btn-outline-warning w-100"
										onClick={() => toggleModalProduct()}>
										{t('cancel')}
									</button>
									<button type="submit" className="btn btn-primary w-100">
										{t('save')}
									</button>
								</div>
							</form>

						</div>
					</div>
				</>
			}
		</>
	)
}

export default Index