import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux"
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import { SET_FILTER_DATA } from "store/filterData/actions"
import DeleteModal from 'pages/parts/DeleteModal'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { httpOk, GET, POST, DELETE, FILE_D } from 'helpers/api'
import {
	formatMoney, returnSign, findKeyFromArrayByValue,
	formatDate, returnMinDate
} from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)
	const reduxFilterData = useSelector(state => state.filterData)

	const [selectedItem, setSelectedItem] = useState({
		'pos_id': reduxAccount.pos_id,
		'payment_purpose_id': 6,
		'amount': '',
		'note': '',
		'account_type': 1,
		'created_at': formatDate(date, 'yyyy-MM-dd'),
	});
	const [modalExpense, setModalExpense] = useState(false);
	const [accounts, setAccounts] = useState([])
	const [accountsChef, setAccountsChef] = useState([])
	const [poses, setPoses] = useState([])
	const [cashiers, setCashiers] = useState([])
	const [paymentPurposeTypes, setPaymentPurposeTypes] = useState([])
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		'start_date': formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		'end_date': formatDate(new Date(), 'yyyy-MM-dd'),
		'pos_id': reduxAccount?.pos_id,
		'search': '',
	})
	const [pagination, setPagination] = useState({
		url: '/report-expenses',
		response: null
	})
	const [data, setData] = useState([])

	async function createExpense(e) {
		e.preventDefault();

		var response = await POST('/expense-web', selectedItem, { loader: true })

		if (httpOk(response)) {
			setModalExpense(false)
			setSelectedItem({
				'payment_purpose_id': 6,
				'amount': '',
				'note': '',
				'created_at': formatDate(date, 'yyyy-MM-dd'),
			})
			searchWithFilter()
		}
	}

	async function searchWithFilter(settings = {}) {
		var filterUrl = "/report-expenses"
		var urlParams = ""
		checkFilter()

		var filterDataCopy = { ...filterData }
		if (settings?.reduxData) {
			filterDataCopy = reduxFilterData
		}

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.payment_purpose_id)
			urlParams += returnSign(urlParams) + 'payment_purpose_id=' + filterDataCopy.payment_purpose_id
		if (filterDataCopy.cashier_login)
			urlParams += returnSign(urlParams) + 'cashier_login=' + filterDataCopy.cashier_login
		if (filterDataCopy.user_login)
			urlParams += returnSign(urlParams) + 'category_id=' + filterDataCopy.category_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		if (`/report-expenses${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function deleteItem() {
		const response = await DELETE(`/expense-web/${deleteModalParams.id}`, {}, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			toggleModalDelete(false)
		}
	}

	async function downloadExcelFile() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/report-expenses"
		var urlParams = "?excel=true"

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.payment_purpose_id)
			urlParams += returnSign(urlParams) + 'payment_purpose_id=' + filterDataCopy.payment_purpose_id
		if (filterDataCopy.cashier_login)
			urlParams += returnSign(urlParams) + 'cashier_login=' + filterDataCopy.cashier_login
		if (filterDataCopy.user_login)
			urlParams += returnSign(urlParams) + 'category_id=' + filterDataCopy.category_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search

		filterDataCopy.urlParams = urlParams

		filterUrl = filterUrl + urlParams
		var response = await FILE_D(filterUrl)

		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `${t('expenses')}.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			setSelectedItem(item)
		} else {
			setSelectedItem({
				'pos_id': reduxAccount.pos_id,
				'payment_purpose_id': 6,
				'amount': '',
				'note': '',
				'created_at': formatDate(date, 'yyyy-MM-dd HH:mm'),
			})
		}

		setModalExpense(bool)
	}

	function toggleModalDelete(bool = false, id = null) {
		if (bool) setDeleteModalParams({ 'id': id })

		setModalDelete(bool)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getPaymentPurposeTypes() {
		const response = await GET('/helper/ppt-expense')
		if (httpOk(response)) setPaymentPurposeTypes(response.data)
	}

	async function getCashiers() {
		const response = await GET('/helper/cashiers')
		if (httpOk(response)) setCashiers(response.data)
	}

	async function getAccountsChef() {
		const response = await GET('/helper/accounts-chef')
		if (httpOk(response)) setAccountsChef(response.data)
	}

	async function getAccounts() {
		const response = await GET('/helper/accounts')
		if (httpOk(response)) setAccounts(response.data)
	}

	useEffect(() => {
		getAccounts()
		getAccountsChef()
		getPoses()
		getPaymentPurposeTypes()
		getCashiers()
		if (reduxFilterData?.filterData?.url === "/report-expenses") {
			setFilterData(reduxFilterData.filterData)
			searchWithFilter({ reduxData: true })
		} else {
			searchWithFilter()
		}

		return () => {
			dispatch(SET_FILTER_DATA({}))
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center h-100">
					{t('expenses')} [{pagination?.response?.total}]
				</h4>

				<div className="d-flex gap-2">
					<button className="btn-rounded btn btn-primary"
						onClick={() => setModalExpense(true)}>
						{t('add')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													isClearable
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('expense_type')}</label>
												<Select
													isClearable
													options={paymentPurposeTypes}
													value={paymentPurposeTypes.find(option => option.id === filterData.payment_purpose_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'payment_purpose_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('user')}</label>
												<Select
													isClearable
													options={cashiers}
													value={cashiers.find(option => option.login === filterData.cashier_login) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'cashier_login': option ? option.login : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.first_name}
													getOptionValue={(option) => option.login}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter()}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide-2"
									title="EXCEL"
									onClick={() => downloadExcelFile()}>
									<i className="uil uil-file-download-alt"></i>
								</button>
							</div>
						</div>
						<div className="vertical-center">
							<h4><b>{t('amount')}</b>: {formatMoney(data.total_amount)}</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.cashier_login &&
									<span className="me-2">
										<b>{t('user')}: </b>
										{findKeyFromArrayByValue(cashiers, 'login', filterData.cashier_login, 'first_name')}
									</span>
								}
								{filterData.payment_purpose_id &&
									<span className="me-2">
										<b>{t('expense_type')}: </b>
										{findKeyFromArrayByValue(paymentPurposeTypes, 'id', filterData.payment_purpose_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '10%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }}>{t('user')}</th>
									<th style={{ 'width': '10%' }}>{t('expense')}</th>
									<th style={{ 'width': '20%' }}>{t('expense_type')}</th>
									<th style={{ 'width': '30%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td className="td-ellipsis" title={item.pos_name}>{item.pos_name}</td>
										<td className="td-ellipsis" title={item.created_by}>{item.created_by}</td>
										<td>{item.account_name}</td>
										<td className="td-ellipsis" title={item.payment_purpose_name}>{item.payment_purpose_name}</td>
										<td className="td-ellipsis" title={item.note}>{item.note}</td>
										<td className="text-center">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-end">{formatMoney(item.amount)}</td>
										<td>
											{/* <div>
												{!!item.deleteable &&
													<div className="table-action-button table-action-danger-button"
														onClick={() => toggleModalDelete(true, item.id)}>
														<i className="uil-times"></i>
													</div>
												}
											</div> */}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL PAYMENT */}
			<Modal show={modalExpense} animation={false}
				centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('expense')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createExpense} autoComplete="off">
						<div className="form-group">
							<label>{t('date')}</label>
							<div className="calendar-input">
								<DatePicker
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={selectedItem.created_at ? new Date(selectedItem.created_at) : ''}
									onChange={(date) => setSelectedItem({ ...selectedItem, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
									minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
									maxDate={new Date()}
								/>
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('pos')}</label>
							<Select
								options={poses}
								value={poses.find(option => option.id === selectedItem.pos_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'pos_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('expense_type')}</label>
							<Select
								isDisabled={selectedItem.update}
								options={paymentPurposeTypes}
								value={paymentPurposeTypes.find(option => option.id === selectedItem.payment_purpose_id)}
								onChange={(option) => setSelectedItem({
									...selectedItem,
									'payment_purpose_id': option.id,
									'account_chef_id': option.account_chef_id,
									'account_core_id': option.account_core_id,
								})}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('operation_account')}</label>
							<Select
								isClearable
								options={accountsChef}
								value={accountsChef.find(option => option.id === selectedItem.account_chef_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'account_chef_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('report_account')}</label>
							<Select
								options={accounts}
								value={accounts.find(option => option.id === selectedItem.account_core_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'account_core_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('amount')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount"
								value={selectedItem.amount ? formatMoney(selectedItem.amount) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={selectedItem.note ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => setModalExpense(false)} tabIndex="-1">
								{t('cancel')}
							</button>
							{selectedItem.id ?
								<button type="submit" className="btn btn-primary w-100"
									disabled={!selectedItem.amount}>
									{t('save')}
								</button>
								:
								<button type="submit" className="btn btn-primary w-100">
									{t('save')}
								</button>
							}
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index