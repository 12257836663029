import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'

import ReactSelect from 'pages/parts/ReactSelect'
import useDidMountEffect from 'pages/parts/useDidMountEffect'
import { httpOk, GET, POST } from 'helpers/api'
import { formatMoney } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({
		"pos_id": reduxAccount.pos_id,
		"elements": [],
		"note": '',
		"total_amount": 0,
	})
	const [addQuantity, setAddQuantity] = useState(1)
	const [poses, setPoses] = useState([])
	const [wms, setWms] = useState([])
	const [toWms, setToWms] = useState([])
	const [elements, setElements] = useState([])
	const [elementsDuplicate, setElementsDuplicate] = useState([])

	async function createUpdate(e) {
		e.preventDefault();
		var dataCopy = { ...data }
		dataCopy.error = false

		if (!dataCopy.elements.length) {
			toast.error(t('add_elements'))
			return
		}

		for (let i = 0; i < dataCopy.elements.length; i++) {
			if (!dataCopy.elements[i]['quantity']) {
				dataCopy.error = true
			}

			if (Number(dataCopy.elements[i]['quantity']) > Number(dataCopy.elements[i]['wms_balance'])) {
				dataCopy.error = true
			}
		}

		var valueArr = dataCopy.elements.map(function (item) { return item.element_id });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx
		});

		if (isDuplicate) {
			toast.error(t('identical_elements'))
			return
		}

		// if (dataCopy.error) {
		// 	toast.error(t('check_filled_inputs'))
		// 	return
		// }

		const response = await POST('/wms-transfer', dataCopy, { loader: true })

		if (httpOk(response)) {
			navigate('/wms-transfer-wms')
		}
	}

	function addToList(length = 1) {
		var dataCopy = JSON.parse(JSON.stringify(data))

		for (let i = 0; i < length; i++) {
			dataCopy.elements.push({
				'price': '',
				'quantity': '',
				'element_id': ''
			})
		}

		countTotalAmount(dataCopy)

		setTimeout(() => {
			const byId = document.getElementById(`input-select-${dataCopy.elements.length}`)
			byId?.select()
		}, 200);
	}

	function deleteItem(index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy.elements.splice(index, 1)

		setData(dataCopy)
	}

	function countTotalAmount(dataCopy) {
		dataCopy.total_amount = 0
		for (let i = 0; i < dataCopy.elements.length; i++) {
			dataCopy.total_amount += Number(dataCopy.elements[i]['quantity']) * Number(dataCopy.elements[i]['price'])
		}

		setData(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.elements[index][e.target.name] = e.target.value
		countTotalAmount(dataCopy)
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (value === '+' || value === '=') return
		if (!value || value === '+' || value === '=') {
			setElements([...elementsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...elementsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setElements(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }

		dataCopy.elements[index]['element_id'] = option.id
		dataCopy.elements[index]['name'] = option.name
		dataCopy.elements[index]['wms_balance'] = option.wms_balance
		dataCopy.elements[index]['cost_price'] = option.cost_price
		dataCopy.elements[index]['unit_name'] = option.unit_name

		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...elements]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setElements(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = elements.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...elements]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[elements.length - 1].selected = true
						currentIndex = elements.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === elements.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setElements(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	async function getPoses() {
		const response = await GET('/helper/poses', {}, { loader: true })

		if (httpOk(response)) setPoses(response.data)
	}

	async function getWms() {
		const response = await GET(`/helper/wms`, {}, { loader: true })

		if (httpOk(response)) setWms(response.data)
	}

	async function getProducts() {
		if (!data.from_wms_id) return

		const response = await GET(`/wms-transfer-elements?from_wms_id=${data.from_wms_id}`, {}, { loader: true })

		if (httpOk(response)) {
			setElements(response.data)
			setElementsDuplicate(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		getWms()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getProducts(data.from_wms_id)
	}, [data.from_wms_id]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('transfer')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('pos')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={data?.elements?.length}
										options={poses}
										value={poses?.find(option => option.id === data?.pos_id)}
										onChange={(option) => setData({ ...data, 'pos_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('from_warehouse')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={data.from_wms_id}
										options={wms}
										value={wms.find(option => option.id === data.from_wms_id)}
										onChange={(option) => {
											setData({ ...data, 'from_wms_id': option.id })
											setToWms(wms.filter(item => item.id !== option.id))
										}}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('to_warehouse')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={data.to_wms_id}
										options={toWms}
										value={toWms.find(option => option.id === data.to_wms_id)}
										onChange={(option) => setData({ ...data, 'to_wms_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control" name="note"
										value={data.note}
										onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '30%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
										<th style={{ 'width': '10%' }} className="text-start">{t('unit')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('cost_price')}</th>
										<th style={{ 'width': '20%' }} className="text-end">{t('wms_balance')}</th>
										<th style={{ 'width': '20%' }} className="text-end">{t('section_balance')}</th>
										<th style={{ 'minWidth': '33px' }} className="text-center"></th>
									</tr>
								</thead>
								<tbody>
									{data.elements.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td>
												<ReactSelect
													uniqueId="select"
													search={handleSelectSearch}
													value={item.name}
													index={index}
													scrollRef={scrollRef}
													options={elements}
													onClose={() => closeReactSelect()}
													selectItem={(option) => handleSelectElement(option, index)}
													onKeyUp={(e) => {
														if (e.keyCode === 40 || e.keyCode === 38) {
															handleTableKeyDown(e)
														}
													}}
													table={
														<table className="table mb-0">
															<tbody ref={tbodyRef}>
																{elements.map((selectItem, selectIndex) => (
																	<tr
																		id={selectItem.id}
																		className={`cursor ${((selectItem.selected || selectItem.id === item.element_id) && 'selected')}`}
																		tabIndex={-1}
																		key={selectIndex}
																		onClick={() => handleSelectElement(selectItem, index)}
																		onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																		<td>{selectItem.name}</td>
																		<td>{selectItem.product_type_name}</td>
																		<td className="text-end text-nowrap">{formatMoney(selectItem.cost_price)}</td>
																	</tr>
																))}
															</tbody>
														</table>
													}
												/>
											</td>
											<td className="td-input">
												<input type="number" className="form-control text-end"
													name="quantity"
													value={item.quantity}
													onChange={(e) => handleInputChange(e, index)}
													onKeyUp={(event) => {
														if (event.key === 'Enter') {
															addToList()
														}
													}} />
											</td>
											<td className="text-start vertical-middle">
												{item.unit_name}
											</td>
											<td className="text-end vertical-middle">
												{formatMoney(item.cost_price)}
											</td>
											<td className="text-end vertical-middle">
												{formatMoney(item.wms_balance)}
											</td>
											<td className="text-end vertical-middle">
												{formatMoney(item.wms_section_balance)}
											</td>
											<td className="vertical-middle">
												<div className="d-flex justify-content-center">
													<div className="table-action-button table-action-danger-button"
														onClick={() => deleteItem(index)}>
														<i className="uil uil-times"></i>
													</div>
												</div>
											</td>
										</tr>
									))}
									<tr>
										<td className="td-input">
											<input
												type="text"
												className="form-control text-center"
												style={{ 'padding': '5px 0' }}
												placeholder="xxx"
												value={addQuantity}
												onChange={(e) => setAddQuantity(e.target.value.replace(/[^0-9.]/g, ''))}
											/>
										</td>
										<td colSpan={1} style={{ 'padding': 0 }}>
											<div className="d-flex">
												<div className="w-100">
													<button type="button" className="btn-sm btn-primary w-100"
														disabled={!data.from_wms_id}
														onClick={() => {
															setAddQuantity(1)
															addToList(addQuantity)
														}}>
														{t('add')}
													</button>
												</div>
											</div>
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>

						<div className="btn-wrapper">
							<Link className="btn btn-warning btn-rounded btn-wide me-2" to="/service/wms-transfer-pos" tabIndex="-1">
								{t('cancel')}
							</Link>
							<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
								onClick={createUpdate}>
								{t('save')}
							</button>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}

export default CreateUpdate