const initialState = {
	'poses': [],
	'menu': [],
	'products': [],
	'comments': [],
	'paymentTypes': [],
	'externalCouriers': [],
}

const filterData = function (state = initialState, action) {
	switch (action.type) {
		case "SET_DELIVERY_DATA":
			return {
				...state,
				'poses': action.payload.poses ?? [],
				'menu': action.payload.menu ?? [],
				'products': action.payload.products ?? [],
				'comments': action.payload.comments ?? [],
				'paymentTypes': action.payload.paymentTypes ?? [],
				'externalCouriers': action.payload.externalCouriers ?? [],
			};
		default:
			return state;
	}
};

export default filterData