import React, { useEffect, useState } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { Dropdown, DropdownButton } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { FILE_D, GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, formatDate, findKeyFromArrayByValue } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/inventory',
		response: null
	})

	async function searchWithFilter() {
		var filterUrl = "/inventory"
		var urlParams = ""
		checkFilter()

		var filterDataCopy = { ...filterData }

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		if (`/inventory${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function downloadExcelFile() {
		var filterUrl = "/inventory-excel"
		var urlParams = ""

		var filterDataCopy = { ...filterData }

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl += urlParams

		var response = await FILE_D(filterUrl)
		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `${t('inventory')}.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: reduxAccount.pos_id ?? '',
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function returnClassStatus(status) {
		if (!status) {
			return "bg-secondary"
		}
		if (status) {
			return "bg-success"
		}
	}

	async function getPoses() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) setPoses(response.data)
	}

	function goTo(type) {
		if (type === 1) {
			navigate("/inventory-wms/create")
		}

		if (type === 2) {
			navigate("/inventory-test")
		}
	}

	useEffect(() => {
		getPoses()
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('inventory')} [{data.total}]
				</h4>
				<DropdownButton title={t('action')}>
					<Dropdown.Item onClick={() => goTo(1)}>
						Инвентаризация элементов
					</Dropdown.Item>
					<Dropdown.Item onClick={() => goTo(2)}>
						Инвентаризация блюд и элементов
					</Dropdown.Item>
				</DropdownButton>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2"
									onClick={() => downloadExcelFile()}>
									<i className="uil uil-file-download-alt"></i>
								</button>
							</div>
						</div>
						<div className="flex-column">
							<h5 className="d-flex gap-2 justify-content-between">
								<strong>{t('surplus')}:</strong>
								<span>{formatMoney(data.surplus)}</span>
							</h5>
							<h5 className="d-flex gap-2 justify-content-between">
								<strong>{t('lack_of')}:</strong>
								<span>{formatMoney(data.shortage)}</span>
							</h5>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('document')}</th>
									<th style={{ 'width': '20%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('surplus')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('shortage')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('divergence')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('status')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center" title={item.id}>
											{item.row_number}
										</td>
										<td className="td-ellipsis">{item.pos_name}</td>
										<td className="text-center">{item.document_number}</td>
										<td className="td-ellipsis">{item.note}</td>
										<td className="text-end">{formatMoney(item.total_surplus)}</td>
										<td className="text-end">{formatMoney(item.total_shortage)}</td>
										<td className="text-end">{formatMoney(item.total_divergence)}</td>
										<td className="text-center text-nowrap">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className={`text-center text-white ${returnClassStatus(item.completed)}`}>
											{!item.completed ?
												<span>{t('expected')}</span>
												:
												<span>{t('closed')}</span>
											}
										</td>
										<td className="d-flex justify-content-center">
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														{item.completed ?
															<Link to={`/inventory-wms/preview/${item.id}`} className="table-dropdown-item">
																<i className="uil-eye"></i>
																{t('preview')}
															</Link>
															:
															<Link to={`/inventory-wms/update/${item.id}`} className="table-dropdown-item">
																<i className="uil-edit-alt"></i>
																{t('edit')}
															</Link>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
