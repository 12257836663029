import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap'
import { toast } from 'react-toastify'

import ReactSelect from 'pages/parts/ReactSelect'
import usePreventExit from "pages/parts/usePreventExit"
import DocumentTemplate from 'pages/parts/DocumentTemplate'
import { httpOk, GET, POST, PUT, DELETE } from 'helpers/api'
import { formatDate, formatMoney, returnMinDate, formatMoneyInput } from 'helpers/helpers'
import CustomTooltip from 'pages/parts/CustomTooltip'

function CreateUpdate() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [isDirty, setIsDirty] = useState(false);
	usePreventExit(isDirty);

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({
		"items": [],
		"pos_id": reduxAccount.pos_id,
		"wms_id": reduxAccount.wms_id,
		"supplier_id": null,
		"total_amount": 0,
		"supplier_amount_in": "",
		"bool_annual": false,
		"created_at": formatDate(new Date(), 'yyyy-MM-dd'),
	})
	const [addQuantity, setAddQuantity] = useState(1)
	const [products, setProducts] = useState([])
	const [elements, setElements] = useState([])
	const [elementsDuplicate, setElementsDuplicate] = useState([])
	const [wms, setWms] = useState([])
	const [suppliers, setSuppliers] = useState([])
	const [problemElements, setProblemElements] = useState([])
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalTemplate, setModalTemplate] = useState({ 'bool': false, 'templates': [] })
	const [modalTemplateCRUD, setModalTemplateCRUD] = useState({ 'bool': false, 'name': "", 'items': [] })
	// NEW ELEMENT
	const [newElement, setNewElement] = useState({})
	const [modalElement, setModalElement] = useState(false)
	const [modalWarning, setModalWarning] = useState(false)
	const [units, setUnits] = useState([])
	// NEW ELEMENT

	async function createUpdate(stage = 1) {
		var dataCopy = { ...data }

		if (dataCopy.items.length === 0) {
			toast.error(t('add_elements'))
			return
		}

		for (let i = 0; i < dataCopy.items.length; i++) {
			if (!dataCopy.items[i]['quantity']) {
				dataCopy.error = true
			}
			if (dataCopy.items[i]['price'] === "" || dataCopy.items[i]['price'] < 0.0001) {
				dataCopy.error = true
			}
			var warningArray = []
			if (stage === 1 &&
				(Number(dataCopy.items[i]['price']) / Number(dataCopy.items[i]['original_price'])) > 2
			) {
				warningArray.push(dataCopy.items[i])
				dataCopy.warning = true
			}
			if (warningArray.length) {
				setProblemElements(warningArray)
			}
		}

		var valueArr = dataCopy.items.map(function (item) { return item.element_id });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx
		});

		if (isDuplicate) {
			toast.error(t('identical_elements'))
			return
		}

		if (dataCopy.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		if (warningArray.length && stage === 1) {
			setModalWarning(true)
			return
		}

		if (stage === 1 || stage === 2) {
			setModalWarning(false)
			setModalConfirm(true)
			return
		}

		var response;
		if (dataCopy.id) {
			response = await PUT('/documents-in', dataCopy, { loader: true })
		} else {
			response = await POST('/documents-in', dataCopy, { loader: true })
		}

		if (httpOk(response)) {
			navigate('/documents-in')
		}
	}

	function addToList(length = 1) {
		var dataCopy = JSON.parse(JSON.stringify(data))

		for (let i = 0; i < length; i++) {
			dataCopy.items.push({
				'price': '',
				'element_id': '',
				'quantity': '',
				'unit_quantity': '',
			})
		}

		setIsDirty(true)
		countTotalAmount(dataCopy)

		setTimeout(() => {
			const byId = document.getElementById(`input-select-${dataCopy.items.length}`)
			byId?.select()
		}, 200);
	}

	function deleteItem(index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy.items.splice(index, 1)

		countTotalAmount(dataCopy)
	}

	function countTotalAmount(dataCopy) {
		dataCopy.total_quantity = 0
		dataCopy.total_amount = 0
		for (let i = 0; i < dataCopy.items.length; i++) {
			dataCopy.total_quantity += Number(dataCopy.items[i]['quantity'])
			dataCopy.total_amount += Number(dataCopy.items[i]['quantity']) * Number(dataCopy.items[i]['price'])
			dataCopy.total_amount = Number(dataCopy.total_amount);
		}

		setData(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }

		dataCopy.items[index][e.target.name] = e.target.value.replace(/[^0-9.]/g, '')
		if (e.target.name === "packaging_quantity") {
			dataCopy.items[index]["quantity"] = Number(dataCopy.items[index]["packaging_quantity"]) * Number(dataCopy.items[index]["unit_quantity"])
		}

		if (e.target.name === "packaging_quantity" && Number(dataCopy.items[index]["price"]) > 0) {
			dataCopy.items[index]["total_price"] =
				(Number(dataCopy.items[index]["quantity"]) * Number(dataCopy.items[index]["price"])).toFixed(2);
		}
		if (e.target.name === "packaging_quantity" && !dataCopy.items[index]["price"]) {
			dataCopy.items[index]["total_price"] = 0
		}


		if (e.target.name === "price" && Number(dataCopy.items[index]["price"]) > 0) {
			dataCopy.items[index]["total_price"] =
				Number((Number(dataCopy.items[index]["quantity"]) * Number(dataCopy.items[index]["price"])).toFixed(2));
		}
		if (e.target.name === "price" && !dataCopy.items[index]["price"]) {
			dataCopy.items[index]["total_price"] = 0
		}

		if (e.target.name === "total_price") {
			dataCopy.items[index]["total_price"] =
				Number(dataCopy.items[index]["total_price"]).toFixed(2);
			dataCopy.items[index]["price"] =
				Number((Number(dataCopy.items[index]["total_price"]) / Number(dataCopy.items[index]["quantity"])).toFixed(2));
		}

		countTotalAmount(dataCopy)
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (value === '+' || value === '=') return
		if (!value || value === '+' || value === '=') {
			setElements([...elementsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...elementsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setElements(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }
		dataCopy.items[index]['element_id'] = option.id
		dataCopy.items[index]['name'] = option.name
		dataCopy.items[index]['balance_id'] = option.balance_id
		dataCopy.items[index]['balance'] = option.balance
		dataCopy.items[index]['bool_loss'] = option.bool_loss
		if (option.price) {
			dataCopy.items[index]['initial_price'] = Number(option.price).toFixed(0)
			dataCopy.items[index]['price'] = Number(option.price).toFixed(0)
			dataCopy.items[index]['original_price'] = Number(option.original_price).toFixed(0)
		} else {
			dataCopy.items[index]['price'] = ""
			dataCopy.items[index]['original_price'] = 0
		}
		dataCopy.items[index]['unit_name'] = option.unit_name
		dataCopy.items[index]['second_unit_name'] = option.second_unit_name
		dataCopy.items[index]['loss_percentage'] = option.loss_percentage
		dataCopy.items[index]['units'] = option.units
		dataCopy.items[index]['total_price'] = ""
		dataCopy.items[index]['packaging_quantity'] = ""
		dataCopy.items[index]['unit_quantity'] = 1

		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...elements]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setElements(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = elements.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...elements]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[elements.length - 1].selected = true
						currentIndex = elements.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === elements.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setElements(elementsCopy)
		}
	}

	function handleSelectItemUnit(option, index) {
		var dataCopy = { ...data }

		dataCopy.items[index]['unit_id'] = option.id
		dataCopy.items[index]['packaging_id'] = option.id
		dataCopy.items[index]['unit_name'] = option.name
		dataCopy.items[index]['unit_quantity'] = option.quantity

		setData(dataCopy)
	}
	// REACTSELECT LOGIC

	// TEMPLATE LOGIC
	async function toggleModalTemplate(bool) {
		if (bool) {
			const response = await GET(`/documents-templates`, {}, { loader: true })
			if (httpOk(response)) {
				setModalTemplate({ ...modalTemplate, 'bool': bool, 'templates': response.data })
			}
		} else {
			setModalTemplate({ ...modalTemplate, 'bool': bool, 'templates': [] })
		}
	}

	async function toggleModalTemplateCRUD(bool) {
		if (bool) {
			const response = await GET(`/documents-templates-all-products`, {}, { loader: true })
			if (httpOk(response)) {
				setProducts(response.data)
				setModalTemplateCRUD({ ...modalTemplateCRUD, 'bool': bool, 'name': "", 'products': [] })
			}
		} else {
			setModalTemplateCRUD({ ...modalTemplateCRUD, 'bool': bool, 'name': "", 'products': [] })
		}
	}

	async function handleSelectTemplate(item) {
		var response = await GET(`/documents-templates-products/${item.id}`, {}, { loader: true })
		if (httpOk(response)) {
			response = response.data

			var items = []
			for (let i = 0; i < response.length; i++) {
				for (let j = 0; j < elementsDuplicate.length; j++) {
					if (response[i]['product_id'] === elementsDuplicate[j]['product_id']) {
						items.push(elementsDuplicate[j])
					}
				}
			}

			setData({ ...data, 'items': items })
			toggleModalTemplate(false)
		}
	}

	async function deleteTemplate(item) {
		const response = await DELETE(`/documents-templates/${item.id}`, {}, { loader: true })
		if (httpOk(response)) toggleModalTemplate(false)
	}
	// TEMPLATE LOGIC

	async function getWms() {
		const responseSuppliers = await GET('/documents-in-suppliers', { 'pos_id': reduxAccount.pos_id }, { loader: true })
		if (httpOk(responseSuppliers)) setSuppliers(responseSuppliers.data)

		const responseWms = await GET(`/helper/wms?pos_id=${reduxAccount.pos_id}`, {}, { loader: true })
		if (httpOk(responseWms)) {
			setWms(responseWms.data)
			getElements(reduxAccount.wms_id)
		}
	}

	async function getElements(wmsId) {
		const response = await GET(`/documents-in-elements?wms_id=${wmsId}`, {}, { loader: true })
		if (httpOk(response)) {
			setElements(response.data)
			setElementsDuplicate(response.data)
		}
	}

	// NEW ELEMENT LOGIC
	async function createElement(e) {
		e.preventDefault();
		var newElementCopy = { ...newElement }
		newElementCopy.wms_id = data.wms_id

		var response = await POST('/elements', newElementCopy, { loader: true })
		if (httpOk(response)) {
			var elementsDuplicateCopy = [...elementsDuplicate]
			elementsDuplicateCopy.push(response.data)
			setElements(elementsDuplicateCopy)
			setElementsDuplicate(elementsDuplicateCopy)
			toggleModalElement(false)
		}
	}

	async function toggleModalElement(bool) {
		setModalElement(bool)
		if (bool && !units.length) {
			const response = await GET('/helper/units')
			if (httpOk(response)) {
				setUnits(response.data)
			}
		}
	}

	function handleSelectUnit(option) {
		setNewElement({ ...newElement, 'unit_id': option.id })
	}
	// NEW ELEMENT LOGIC

	useEffect(() => {
		getWms()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('good_reception')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
				<DropdownButton title={t('action')}>
					<Dropdown.Item onClick={() => toggleModalTemplate(true)}>
						{t('templates')}
					</Dropdown.Item>
					<Dropdown.Item onClick={() => toggleModalTemplateCRUD(true)}>
						{t('create_template')}
					</Dropdown.Item>
					<Dropdown.Item onClick={() => toggleModalElement(true)}>
						{t('element')}
					</Dropdown.Item>
				</DropdownButton>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('date')}<span className="required-mark">*</span></label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={data.created_at ? new Date(data.created_at) : ''}
											onChange={(date) => setData({ ...data, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
											minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
											maxDate={new Date()} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('supplier')}<span className="required-mark">*</span></label>
									<Select
										options={suppliers}
										value={suppliers.find(option => option.id === data.supplier_id)}
										onChange={(option) => setData({ ...data, 'supplier_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('warehouse')}<span className="required-mark">*</span></label>
									<Select
										options={wms}
										value={wms.find(option => option.id === data.wms_id)}
										onChange={(option) => setData({ ...data, 'wms_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control"
										name="note"
										onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<table className="table table-bordered" style={{ 'overflow': 'unset' }}>
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name2')}</th>
									<th style={{ 'width': '10%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
									<th style={{ 'width': '10%' }} className="text-start">{t('unit')}</th>
									<th style={{ 'width': '10%' }} className="text-end">
										{t('quantity')}<span className="required-mark">*</span>
									</th>
									<th style={{ 'width': '10%' }} className="text-end">
										{t('fact')}
									</th>
									<th style={{ 'width': '10%' }} className="text-end">
										{t('price')}<span className="required-mark">*</span>
									</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('amount')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.items?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>
											<ReactSelect
												uniqueId="select"
												search={handleSelectSearch}
												value={item.name}
												index={index}
												scrollRef={scrollRef}
												options={elements}
												onClose={() => closeReactSelect()}
												selectItem={(option) => handleSelectElement(option, index)}
												onKeyUp={(e) => {
													if (e.keyCode === 40 || e.keyCode === 38) {
														handleTableKeyDown(e)
													}
												}}
												table={
													<table className="table mb-0">
														<tbody ref={tbodyRef}>
															{elements.map((selectItem, selectIndex) => (
																<tr
																	id={selectItem.id}
																	className={`cursor ${((selectItem.selected || selectItem.id === item.element_id) && 'selected')}`}
																	tabIndex={-1}
																	key={selectIndex}
																	onClick={() => handleSelectElement(selectItem, index)}
																	onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																	<td>{selectItem.name}</td>
																	<td>{selectItem.product_type_name}</td>
																	<td className="text-end text-nowrap">{formatMoney(selectItem.price)}</td>
																</tr>
															))}
														</tbody>
													</table>
												}
											/>
										</td>
										<td></td>
										<td className="text-end text-nowrap vertical-middle">
											{formatMoney(item.balance)}
										</td>
										<td>
											<ReactSelect
												tabIndex={-1}
												uniqueId="select2"
												search={handleSelectSearch}
												value={item.unit_name}
												index={index}
												scrollRef={scrollRef}
												options={item.units}
												onClose={() => closeReactSelect()}
												selectItem={(option) => handleSelectItemUnit(option, index)}
												onKeyUp={(e) => {
													if (e.keyCode === 40 || e.keyCode === 38) {
														handleTableKeyDown(e)
													}
												}}
												table={
													<table className="table mb-0">
														<tbody ref={tbodyRef}>
															{item?.units?.map((selectItem, selectIndex) => (
																<tr
																	id={selectItem.id}
																	className={`cursor ${((selectItem.selected || selectItem.id === item.unit_id) && 'selected')}`}
																	tabIndex={-1}
																	key={selectIndex}
																	onClick={() => handleSelectItemUnit(selectItem, index)}
																	onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																	<td>{selectItem.name}</td>
																</tr>
															))}
														</tbody>
													</table>
												}
											/>
										</td>
										<td className="td-input" style={!item.packaging_quantity ? { 'borderBottom': '2px solid red' } : {}}>
											<input
												type="text"
												name="packaging_quantity"
												className="form-control text-end"
												placeholder="0"
												value={formatMoneyInput(item.packaging_quantity) ?? ''}
												onChange={(e) => handleInputChange(e, index)} />
										</td>
										<td className="text-end">{item.quantity}</td>
										<td className="td-input" style={!item.price ? { 'borderBottom': '2px solid red' } : {}}>
											<input
												type="text"
												name="price"
												placeholder="0"
												className="form-control text-end"
												value={item.price ?? ''}
												onChange={(e) => handleInputChange(e, index)}
											/>
										</td>
										<td className="td-input" style={!item.total_price ? { 'borderBottom': '2px solid red' } : {}}>
											<input
												type="text"
												name="total_price"
												placeholder="0"
												className="form-control text-end"
												value={item.total_price ?? ''}
												onChange={(e) => handleInputChange(e, index)}
												onKeyUp={(event) => {
													if (event.key === 'Enter') {
														addToList()
													}
												}}
											/>
										</td>
										<td className="vertical-middle">
											<div className="d-flex justify-content-end">
												<div className="table-action-button table-action-danger-button"
													onClick={() => deleteItem(index)}>
													<i className="uil uil-times"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
								<tr>
									<td className="td-input">
										<input
											type="text"
											className="form-control text-center"
											style={{ 'padding': '5px 0' }}
											placeholder="xxx"
											value={addQuantity}
											onChange={(e) => setAddQuantity(e.target.value.replace(/[^0-9.]/g, ''))}
										/>
									</td>
									<td colSpan={1} style={{ 'padding': 0 }}>
										<div className="d-flex">
											<div className="w-100">
												<button type="button" className="btn-sm btn-primary w-100"
													disabled={!data.wms_id}
													onClick={() => {
														setAddQuantity(1)
														addToList(addQuantity)
													}}>
													{t('add')}
												</button>
											</div>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td className="text-end">
										{formatMoney(data.total_quantity)}
									</td>
									<td></td>
									<td className="text-end">
										{formatMoney(data.total_amount, 2)}
									</td>
									<td></td>
								</tr>
							</tbody>
						</table>

						<div className="w-100 d-flex justify-content-between">
							<CustomTooltip text={t('annual_negative_values')}>
								<div className="d-flex justify-content-between">
									<p className="fw-600 vertical-center">{t('annual')}</p>
									<div className="form-check form-switch form-switch-lg flex-column justify-content-center">
										<input type="checkbox" className="form-check-input"
											name="bool_annual"
											checked={data?.bool_annual}
											onChange={(e) => setData({ ...data, 'bool_annual': e.target.checked })}
										/>
									</div>
								</div>
							</CustomTooltip>
						</div>

						<div className="btn-wrapper gap-2">
							<Link to="/documents-in" className="btn btn-warning btn-rounded btn-wide" tabIndex="-1">
								{t('cancel')}
							</Link>
							<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
								onClick={() => createUpdate(1)}>
								{t('save')}
							</button>
						</div>
					</div>
				</div>
			</form>

			{/* MODAL WARNING */}
			<Modal show={modalWarning} animation={false} centered size="md"
				dialogClassName="update-modal-width" onHide={() => setModalWarning(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<b className="text-danger">{t('warning')}!</b>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '45%' }}>{t('name2')}</th>
									<th style={{ 'width': '15%' }}>{t('unit')}</th>
									<th className="text-end" style={{ 'width': '20%' }}>{t('before')}</th>
									<th className="text-end" style={{ 'width': '20%' }}>{t('after')}</th>
								</tr>
							</thead>
							<tbody>
								{problemElements?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td>{item.unit_name}</td>
										<td className="text-end">{formatMoney(item.original_price)}</td>
										<td className="text-end">{formatMoney(item.price)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="btn-wrapper gap-2">
						<button type="button" className="btn btn-warning btn-rounded btn-wide" tabIndex="-1"
							onClick={() => setModalWarning(false)}>
							{t('cancel')}
						</button>
						<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
							onClick={() => createUpdate(2)}>
							{t('continue')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL WARNING */}

			{/* MODAL CONFIRM */}
			<Modal show={modalConfirm} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => setModalConfirm(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => createUpdate(3)}>
							{t('confirm')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL CONFIRM */}

			{/* MODAL CREATE ELEMENT */}
			<Modal show={modalElement} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => toggleModalElement(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('create')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createElement(e)} autoComplete="off">
						<div className="form-group w-100">
							<label>{t('name2')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name"
								value={newElement.name ?? ''}
								onChange={(e) => setNewElement({ ...newElement, 'name': e.target.value })} />
						</div>
						<div className="form-group w-100">
							<label>{t('unit')}<span className="required-mark">*</span></label>
							<Select
								options={units}
								value={units.find(option => option.id === newElement.unit_id)}
								onChange={(option) => handleSelectUnit(option)}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group w-100">
							<label>{t('weight_one_unit_kg')}</label>
							<input type="number" className="form-control" name="quantity"
								value={newElement.quantity ?? ''}
								onChange={(e) => setNewElement({ ...newElement, 'quantity': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => toggleModalElement(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!(newElement.name && newElement.unit_id)}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL CREATE ELEMENT */}

			{/* MODAL TEMPLATE */}
			<Modal show={modalTemplate.bool} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => toggleModalTemplate(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('templates')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '100%' }}>{t('name2')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{modalTemplate?.templates?.map((item, index) => (
									<tr key={index}>
										<td className="text-center" style={{ 'minWidth': '33px' }}>{index + 1}</td>
										<td className="w-100"
											onClick={() => handleSelectTemplate(item)}>
											{item.name}
										</td>
										<td lassName="text-center" style={{ 'minWidth': '33px' }}>
											<div className="d-flex justify-content-end">
												<div className="table-action-button table-action-danger-button"
													onClick={() => deleteTemplate(item)}>
													<i className="uil uil-times"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL TEMPLATE */}

			{/* MODAL TEMPLATE */}
			{modalTemplateCRUD.bool &&
				<DocumentTemplate
					products={products}
					modalTemplateCRUD={modalTemplateCRUD}
					setModalTemplateCRUD={setModalTemplateCRUD}
					toggleModalTemplateCRUD={toggleModalTemplateCRUD}>
				</DocumentTemplate>
			}
			{/* MODAL TEMPLATE */}
		</>
	)
}

export default CreateUpdate