import React from 'react'

import Login from '../pages/auth/Login'
import ForgotPassword from "../pages/auth/ForgotPassword"

import Pos from '../pages/main/pos/Index'
import PosCreateUpdate from '../pages/main/pos/CreateUpdate'

import Dashboard from '../pages/main/dashboard/Main'
import DashboardCheques from '../pages/main/dashboard/Cheques'
import DashboardExpense from '../pages/main/dashboard/Expense'
import DashboardElements from '../pages/main/dashboard/Elements'

import Hall from '../pages/main/halls/Index'
import HallScheme from '../pages/main/halls/Scheme'
import Table from '../pages/main/tables/Index'
import OrderTypes from '../pages/main/orderTypes/Index'
import PaymentTypes from '../pages/main/paymentTypes/Index'
import Categories from '../pages/main/categories/Index'
import Comment from '../pages/main/comments/Index'
import Element from '../pages/main/elements/Index'
import Product from '../pages/main/products/Index'
import PriceСhange from '../pages/main/products/PriceСhange'
import ProductCreateUpdate from '../pages/main/products/CreateUpdate'
import Employee from '../pages/main/employees/Index'
import EmployeeCreateUpdate from '../pages/main/employees/CreateUpdate'
import Menu from '../pages/main/menu/Index'
import Supplier from '../pages/main/suppliers/Index'
import Expense from '../pages/main/paymentPuposeTypes/Index'
import ExpensePlan from '../pages/main/paymentPuposePlan/Index'
import Cashbox from '../pages/main/cashbox/Index'
import Shift from '../pages/main/cashbox/Shift'
import Clients from '../pages/main/clients/Index'
import ClientsCards from '../pages/main/clients/Cards'
import Organizations from '../pages/main/organizations/Index'
import Agents from '../pages/main/agents/Index'
import EmployeePositions from '../pages/main/employeePositions/Index'
import EmployeeBonus from '../pages/main/employeeKPI/Index'
import MenuProductOrder from '../pages/main/menuProductOrder/Index'
import EmployeeSalary from '../pages/main/employeeSalary/Index'
import Cheques from '../pages/main/cheques/Index'
import ChequesRating from '../pages/main/cheques/Rating'
import ChequesByClients from '../pages/main/cheques/ByClients'
import OnlineUsers from '../pages/main/onlineUsers/Index'
import Synchronization from '../pages/main/onlineUsers/Synchronization'
import DeletionReasons from '../pages/main/deletionReasons/Index'
import TaxCategory from '../pages/main/taxCategories/Index'

import DocumentsIn from '../pages/main/documentsIn/Index'
import DocumentsInCreateUpdate from '../pages/main/documentsIn/CreateUpdate'
import DocumentsInPreview from '../pages/main/documentsIn/Preview'

import DocumentsReturn from '../pages/main/documentsReturn/Index'
import DocumentsReturnCreateUpdate from '../pages/main/documentsReturn/CreateUpdate'
import DocumentsReturnPreview from '../pages/main/documentsReturn/Preview'

import DocumentsImplementation from '../pages/main/documentsImplementation/Index'
import DocumentsImplementationCreateUpdate from '../pages/main/documentsImplementation/CreateUpdate'
import DocumentsImplementationPreview from '../pages/main/documentsImplementation/Preview'

import DocumentPreparation from '../pages/main/documentsPreparation/Index'
import DocumentPreparationCreateUpdate from '../pages/main/documentsPreparation/CreateUpdate'
import DocumentPreparationPreview from '../pages/main/documentsPreparation/Preview'

import Orders from '../pages/main/documentsOrders/Index'
import OrdersCreateUpdate from '../pages/main/documentsOrders/CreateUpdate'
import OrdersPreview from '../pages/main/documentsOrders/Preview'

import ServiceDocumentsIn from '../pages/main/service/documentsIn/Index'
import ServiceDocumentsInPreview from '../pages/main/service/documentsIn/Preview'

import ServiceOrders from '../pages/main/service/documentsOrders/Index'
import ServiceOrdersPreview from '../pages/main/service/documentsOrders/Preview'

import InventoryTest from "../pages/main/inventory/Main"
import InventoryTest2 from "../pages/main/inventory/MainPreview"

import InventoryIndex from "../pages/main/inventory/Index"
import InventoryCreateUpdate from "../pages/main/inventory/CreateUpdate"
import InventoryPreview from "../pages/main/inventory/Preview"

import WriteOff from '../pages/main/writeOff/Index'
import WriteOffCreateUpdate from '../pages/main/writeOff/CreateUpdate'
import WriteOffPreview from '../pages/main/writeOff/Preview'

import WmsShifts from '../pages/main/wms/cashbox/Shifts'

import Wms from '../pages/main/wms/Index'
import WmsTransfer from '../pages/main/wms/transfer/Index'
import WmsTransferCreateUpdate from '../pages/main/wms/transfer/CreateUpdate'
import WmsTransferPreview from '../pages/main/wms/transfer/Preview'
import WmsTransferReturn from '../pages/main/wms/transfer/Return'

import WmsTransferToBranch from '../pages/main/wms/transferBrach/Index'
import WmsTransferToBranchCreate from '../pages/main/wms/transferBrach/CreateUpdate'
import WmsTransferToBranchPreview from '../pages/main/wms/transferBrach/Preview'

import WmsTransferBranchDocumentIn from '../pages/main/wms/documentsIn/Index'
import WmsTransferBranchDocumentInPreview from '../pages/main/wms/documentsIn/Preview'

import WmsTransferToPos from '../pages/main/service/transfer/Index'
import WmsTransferToPosCreate from '../pages/main/service/transfer/CreateUpdate'
import WmsTransferToPosPreview from '../pages/main/service/transfer/Preview'

import ReportPosSafe from '../pages/main/reports/PosSafe'
import ReportPosBank from '../pages/main/reports/PosBank'
import ReportAgents from '../pages/main/reports/Agents'
import ReportAgentsInnerCheques from '../pages/main/reports/AgentsInnerCheques'
import ReportAgentsInnerDebt from '../pages/main/reports/AgentsInnerDebts'
import ReportAverageCheque from '../pages/main/reports/AverageCheque'
import ReportBalance from '../pages/main/reports/Balance'
import ReportCalculation from '../pages/main/reports/Calculation'
import ReportClients from '../pages/main/reports/ClientDebt'
import ReportClientsInnerDebts from '../pages/main/reports/ClientDebtInner'
import ReportOrganizations from '../pages/main/reports/Organizations'
import ReportOrganizationsInnerDebts from '../pages/main/reports/OrganizationsInnerDebts'
import ReportEmployee from '../pages/main/reports/Employee'
import ReportExpenses from '../pages/main/reports/Expenses'
import ReportGrossIncome from '../pages/main/reports/GrossIncome'
import ReportIncomes from '../pages/main/reports/Incomes'
import ReportMenu from '../pages/main/reports/Menu'
import ReportMenu2 from '../pages/main/reports/Menu2'
import ReportSuppliers from '../pages/main/reports/Supplier'
import ReportSuppliersInner from '../pages/main/reports/SupplierInnerDebt'
import ReportEmployees from '../pages/main/reports/EmployeeDebt'
import ReportEmployeesInner from '../pages/main/reports/EmployeeDebtInner'
import ReportProducts from '../pages/main/reports/Products'
import ReportSales from '../pages/main/reports/Sales'
import ReportSummary from '../pages/main/reports/Summary'
import ReportSummaryCheques from '../pages/main/reports/SummaryCheques'
import ReportZ from '../pages/main/reports/ReportZ'
import ReportCheckPoint from '../pages/main/reports/CheckPoint'
import ReportProfitLoses from '../pages/main/reports/ProfitLoses'
import ReportWriteOff from '../pages/main/reports/WriteOff'

import ChainPosesPlans from '../pages/main/chain/plans/Index'
import ChainBalance from '../pages/main/chain/reports/ChainBalance'
import ChainElementSales from '../pages/main/chain/reports/ChainElementSales'
import ChainElementIncomes from '../pages/main/chain/reports/ChainElementIncomes'
import ChainProductSales from '../pages/main/chain/reports/ChainProductSales'
import ChainWmsTransferPos from '../pages/main/chain/reports/ChainWmsTransferPos'
import ChainWmsTransferPosSummary from '../pages/main/chain/reports/ChainWmsTransferPosSummary'

import ChainSales from '../pages/main/chain/reports/ChainSales'
import ChainHours from '../pages/main/chain/reports/ChainHours'
import ChainPlanFact from '../pages/main/chain/reports/ChainPlanFact'
import ChainContourMarket from '../pages/main/chain/reports/ChainContourMarket'
import ChainChequeRating from '../pages/main/chain/reports/ChainChequeRating'
import ChainServiceRating from '../pages/main/chain/reports/ChainServiceRating'
import ChainDeliveryTracking from '../pages/main/chain/reports/ChainCourierTracking'

// FINANCE
import FinanceAccounts from '../pages/main/finance/accounts/Index'

// notification
import TelegramSending from '../pages/main/notifications/telegramBot/Index'
import SmsSending from '../pages/main/notifications/sms/Index'
import SmsTemplates from '../pages/main/notifications/smsTemplates/Index'

// Settings
import ServiceRatingTemplates from '../pages/main/serviceRatingTemplates/Index'

import SettingLayout from '../pages/main/themes/Index'
import Payment from '../pages/main/payment/Payment'

import Loyalty from '../pages/main/loyalty/Index'
import LoyaltyCreateUpdateAmount from '../pages/main/loyalty/CreateUpdateAmount'
import LoyaltyCreateUpdateSaving from '../pages/main/loyalty/CreateUpdateSaving'
import LoyaltyCreateUpdateVisit from '../pages/main/loyalty/CreateUpdateVisit'

import Discount from '../pages/main/discount/Index'
import DiscountCreateUpdateDish from '../pages/main/discount/CreateUpdateDish'
import DiscountCreateUpdateManual from '../pages/main/discount/CreateUpdateManual'
import DiscountCreateUpdateCheque from '../pages/main/discount/CreateUpdateCheque'
import DiscountCreateUpdateFirstOrder from '../pages/main/discount/CreateUpdateFirstOrder'

import DiscountPromocodes from '../pages/main/discountPromocodes/Index'
import DiscountPromocodesReport from '../pages/main/discountPromocodes/Report'

import Calendar from '../pages/main/calendar/Index'

import ExternalGroups from '../pages/main/external/Group'
import ExternalMenu from '../pages/main/external/Menu'
import ExternalDeliveryRules from '../pages/main/external/DeliveryRules'
import TelegramBots from '../pages/main/external/TelegramBots'
import IntegrationGroup from '../pages/main/external/Integration'
import IntegrationUpdate from '../pages/main/external/IntegrationUpdate'
import ExternalCallCenter from '../pages/main/external/CallCenter'
import ExternalPosOrders from '../pages/main/external/PosOrders'
import ExternalCouriers from '../pages/main/external/ExternalCouriers'

// ADMIN
import AdminUser from '../pages/admin/users/Index'
import AdminPos from '../pages/admin/pos/Index'
import AdminPayment from '../pages/admin/paymentsHistory/Index'
import AdminOnline from '../pages/admin/onlineUsers/Index'
import AdminDuplicate from '../pages/admin/duplicate/Index'
import AdminSql from '../pages/admin/sql/Index'
import AdminElements from '../pages/admin/elements/Index'
import AdminPermissions from '../pages/admin/permissions/Index'

// DELIVERY
import DeliveryOrders from '../pages/delivery/Orders'
import DeliveryCreate from '../pages/delivery/OrderCreate'

// PARTNER
import PartnerDashboard from '../pages/partner/Dashboard'
import PartnerPoses from '../pages/partner/Poses'
import PartnerPosesTransactions from '../pages/partner/Transactions'
import LoginMap from 'pages/auth/Map'

const publicRoutes = [
	{ path: "/auth/login", component: <Login /> },
	{ path: "/auth/map", component: <LoginMap /> },
	{ path: "/auth/forgot-password", component: <ForgotPassword /> },
]

const privateRoutes = [
	{ path: "/dashboard-main", component: <Dashboard /> },
	{ path: "/dashboard-cheques", component: <DashboardCheques /> },
	{ path: "/dashboard-expenses", component: <DashboardExpense /> },
	{ path: "/dashboard-elements", component: <DashboardElements /> },

	{ path: "/halls", component: <Hall /> },
	{ path: "/halls/scheme/:id", component: <HallScheme /> },
	{ path: "/order-types-subs", component: <OrderTypes /> },
	{ path: "/payment-types", component: <PaymentTypes /> },
	{ path: "/categories", component: <Categories /> },
	{ path: "/deletion-reasons", component: <DeletionReasons /> },
	{ path: "/comments", component: <Comment /> },
	{ path: "/tables", component: <Table /> },
	{ path: "/elements", component: <Element /> },
	{ path: "/products", component: <Product /> },
	{ path: "/price-change", component: <PriceСhange /> },
	{ path: "/products/create/:posId", component: <ProductCreateUpdate /> },
	{ path: "/products/update/:posId/:id", component: <ProductCreateUpdate /> },

	{ path: "/pos", component: <Pos /> },
	{ path: "/pos/create", component: <PosCreateUpdate /> },
	{ path: "/pos/update/:id", component: <PosCreateUpdate /> },
	{ path: "/employees", component: <Employee /> },
	{ path: "/employees/create", component: <EmployeeCreateUpdate /> },
	{ path: "/employees/update/:id", component: <EmployeeCreateUpdate /> },
	{ path: "/menu", component: <Menu /> },
	{ path: "/suppliers", component: <Supplier /> },
	{ path: "/payment-pupose-types", component: <Expense /> },
	{ path: "/expense-plans", component: <ExpensePlan /> },
	{ path: "/cashbox", component: <Cashbox /> },
	{ path: "/cashbox/shift/:cashbox_id", component: <Shift /> },
	{ path: "/clients", component: <Clients /> },
	{ path: "/clients-cards", component: <ClientsCards /> },
	{ path: "/organizations", component: <Organizations /> },
	{ path: "/agents", component: <Agents /> },
	{ path: "/employee-positions", component: <EmployeePositions /> },
	{ path: "/employee-salary", component: <EmployeeSalary /> },
	{ path: "/employee-bonus", component: <EmployeeBonus /> },
	{ path: "/product-order", component: <MenuProductOrder /> },

	{ path: "/documents-in", component: <DocumentsIn /> },
	{ path: "/documents-in/create", component: <DocumentsInCreateUpdate /> },
	{ path: "/documents-in/preview/:id", component: <DocumentsInPreview /> },

	{ path: "/documents-return", component: <DocumentsReturn /> },
	{ path: "/documents-return/create", component: <DocumentsReturnCreateUpdate /> },
	{ path: "/documents-return/preview/:id", component: <DocumentsReturnPreview /> },

	{ path: "/documents-implementation", component: <DocumentsImplementation /> },
	{ path: "/documents-implementation/create", component: <DocumentsImplementationCreateUpdate /> },
	{ path: "/documents-implementation/preview/:id", component: <DocumentsImplementationPreview /> },

	{ path: "/documents-orders", component: <Orders /> },
	{ path: "/documents-orders/create", component: <OrdersCreateUpdate /> },
	{ path: "/documents-orders/update/:id", component: <OrdersCreateUpdate /> },
	{ path: "/documents-orders/preview/:id", component: <OrdersPreview /> },

	{ path: "/documents-preparation", component: <DocumentPreparation /> },
	{ path: "/documents-preparation/create", component: <DocumentPreparationCreateUpdate /> },
	{ path: "/documents-preparation/preview/:id", component: <DocumentPreparationPreview /> },

	{ path: "/service/idocuments-in", component: <ServiceDocumentsIn /> },
	{ path: "/service/idocuments-in/preview/:id", component: <ServiceDocumentsInPreview /> },

	{ path: "/inventory-test", component: <InventoryTest /> },
	{ path: "/inventory-test/:id", component: <InventoryTest2 /> },
	{ path: "/inventory-wms", component: <InventoryIndex /> },
	{ path: "/inventory-wms/create", component: <InventoryCreateUpdate /> },
	{ path: "/inventory-wms/update/:id", component: <InventoryCreateUpdate /> },
	{ path: "/inventory-wms/preview/:id", component: <InventoryPreview /> },

	{ path: "/write-off", component: <WriteOff /> },
	{ path: "/write-off/create", component: <WriteOffCreateUpdate /> },
	{ path: "/write-off/preview/:id", component: <WriteOffPreview /> },

	{ path: "/loyalty/services", component: <Loyalty /> },
	{ path: "/loyalty/services/create-amount", component: <LoyaltyCreateUpdateAmount /> },
	{ path: "/loyalty/services/update-amount/:id", component: <LoyaltyCreateUpdateAmount /> },
	{ path: "/loyalty/services/create-saving", component: <LoyaltyCreateUpdateSaving /> },
	{ path: "/loyalty/services/update-saving/:id", component: <LoyaltyCreateUpdateSaving /> },
	{ path: "/loyalty/services/create-visit", component: <LoyaltyCreateUpdateVisit /> },
	{ path: "/loyalty/services/update-visit/:id", component: <LoyaltyCreateUpdateVisit /> },

	{ path: "/discount/services", component: <Discount /> },
	{ path: "/discount/services/create-dish", component: <DiscountCreateUpdateDish /> },
	{ path: "/discount/services/update-dish/:id", component: <DiscountCreateUpdateDish /> },
	{ path: "/discount/services/create-manual", component: <DiscountCreateUpdateManual /> },
	{ path: "/discount/services/create-manual/:id", component: <DiscountCreateUpdateManual /> },
	{ path: "/discount/services/create-cheque", component: <DiscountCreateUpdateCheque /> },
	{ path: "/discount/services/create-cheque/:id", component: <DiscountCreateUpdateCheque /> },
	{ path: "/discount/services/create-first-order", component: <DiscountCreateUpdateFirstOrder /> },
	{ path: "/discount/services/update-first-order/:id", component: <DiscountCreateUpdateFirstOrder /> },

	{ path: "/discount/promo-codes", component: <DiscountPromocodes /> },
	{ path: "/discount/promo-codes/:id", component: <DiscountPromocodesReport /> },

	{ path: "/wms-all", component: <Wms /> },
	{ path: "/service-rating-templates", component: <ServiceRatingTemplates /> },

	{ path: "/tax-categories", component: <TaxCategory /> },

	{ path: "/wms-shifts", component: <WmsShifts /> },

	{ path: "/wms-transfer-wms", component: <WmsTransfer /> },
	{ path: "/wms-transfer-wms/create", component: <WmsTransferCreateUpdate /> },
	{ path: "/wms-transfer-wms/preview/:id", component: <WmsTransferPreview /> },
	{ path: "/wms-transfer-wms/return", component: <WmsTransferReturn /> },

	{ path: "/wms-transfer-branch", component: <WmsTransferToBranch /> },
	{ path: "/wms-transfer-branch/create", component: <WmsTransferToBranchCreate /> },
	{ path: "/wms-transfer-branch/update/:id", component: <WmsTransferToBranchCreate /> },
	{ path: "/wms-transfer-branch/preview/:id", component: <WmsTransferToBranchPreview /> },

	{ path: "/wms-transfer-branch/documents", component: <WmsTransferBranchDocumentIn /> },
	{ path: "/wms-transfer-branch/documents/preview/:id", component: <WmsTransferBranchDocumentInPreview /> },

	{ path: "/service/wms-transfer-pos", component: <WmsTransferToPos /> },
	{ path: "/service/wms-transfer-pos/create", component: <WmsTransferToPosCreate /> },
	{ path: "/service/wms-transfer-pos/update/:id", component: <WmsTransferToPosCreate /> },
	{ path: "/service/wms-transfer-pos/preview/:id", component: <WmsTransferToPosPreview /> },

	{ path: "/service/idocuments-orders", component: <ServiceOrders /> },
	{ path: "/service/idocuments-orders/preview/:id", component: <ServiceOrdersPreview /> },

	{ path: "/cheques-all", component: <Cheques /> },
	{ path: "/cheques-rating", component: <ChequesRating /> },
	{ path: "/cheques-by-clients", component: <ChequesByClients /> },
	{ path: "/online-users", component: <OnlineUsers /> },
	{ path: "/synchronizations", component: <Synchronization /> },

	{ path: "/report-suppliers", component: <ReportSuppliers /> },
	{ path: "/report-suppliers-debts/:supplier_id", component: <ReportSuppliersInner /> },
	{ path: "/report-employees", component: <ReportEmployees /> },
	{ path: "/report-employees-debts/:employee_id", component: <ReportEmployeesInner /> },
	{ path: "/report-clients", component: <ReportClients /> },
	{ path: "/report-clients-debts/:client_id", component: <ReportClientsInnerDebts /> },
	{ path: "/report-organizations", component: <ReportOrganizations /> },
	{ path: "/report-organizations-debts/:organization_id", component: <ReportOrganizationsInnerDebts /> },

	{ path: "/report-agents", component: <ReportAgents /> },
	{ path: "/report-safe", component: <ReportPosSafe /> },
	{ path: "/report-bank", component: <ReportPosBank /> },
	{ path: "/report-agents-cheques", component: <ReportAgentsInnerCheques /> },
	{ path: "/report-agents-debt", component: <ReportAgentsInnerDebt /> },
	{ path: "/report-average-cheque", component: <ReportAverageCheque /> },
	{ path: "/report-balance", component: <ReportBalance /> },
	{ path: "/report-calculation", component: <ReportCalculation /> },
	{ path: "/report-employee", component: <ReportEmployee /> },
	{ path: "/report-expenses", component: <ReportExpenses /> },
	{ path: "/report-gross-income", component: <ReportGrossIncome /> },
	{ path: "/report-incomes", component: <ReportIncomes /> },
	{ path: "/report-write-off", component: <ReportWriteOff /> },
	{ path: "/report-menu", component: <ReportMenu /> },
	{ path: "/report-menu2", component: <ReportMenu2 /> },
	{ path: "/report-products", component: <ReportProducts /> },
	{ path: "/report-sales", component: <ReportSales /> },
	{ path: "/report-cheques-summary", component: <ReportSummaryCheques /> },
	{ path: "/report-summary", component: <ReportSummary /> },
	{ path: "/report-z", component: <ReportZ /> },
	{ path: "/report-check-point", component: <ReportCheckPoint /> },
	{ path: "/report-profit-loses", component: <ReportProfitLoses /> },
	{ path: "/chain/poses-plans", component: <ChainPosesPlans /> },
	{ path: "/chain/balance", component: <ChainBalance /> },
	{ path: "/chain/element-incomes", component: <ChainElementIncomes /> },
	{ path: "/chain/element-sales", component: <ChainElementSales /> },
	{ path: "/chain/product-sales", component: <ChainProductSales /> },
	{ path: "/chain/external-transfer", component: <ChainWmsTransferPos /> },
	{ path: "/chain/external-transfer-summary", component: <ChainWmsTransferPosSummary /> },

	{ path: "/chain/report-sales", component: <ChainSales /> },
	//{ path: "/chain/report-abc", component: <ChainAbc /> },
	{ path: "/chain/report-plan-fact", component: <ChainPlanFact /> },
	{ path: "/chain/report-contour-market", component: <ChainContourMarket /> },
	{ path: "/chain/report-hours", component: <ChainHours /> },
	{ path: "/chain/report-cheque-rating", component: <ChainChequeRating /> },
	{ path: "/chain/report-service-rating", component: <ChainServiceRating /> },
	{ path: "/chain/report-courier-tracking", component: <ChainDeliveryTracking /> },

	{ path: "/external-menu", component: <ExternalGroups /> },
	{ path: "/external-menu/:id", component: <ExternalMenu /> },
	{ path: "/external-integrations", component: <IntegrationGroup /> },
	{ path: "/external-integrations/:id", component: <IntegrationUpdate /> },
	{ path: "/external-delivery-rules", component: <ExternalDeliveryRules /> },
	{ path: "/external-telegram-bots", component: <TelegramBots /> },
	{ path: "/external-call-center", component: <ExternalCallCenter /> },
	{ path: "/external-pos-orders", component: <ExternalPosOrders /> },
	{ path: "/external-couriers", component: <ExternalCouriers /> },

	{ path: "/finance/accounts", component: <FinanceAccounts /> },

	{ path: "/telegram-sending", component: <TelegramSending /> },
	{ path: "/sms-sending", component: <SmsSending /> },
	{ path: "/sms-templates", component: <SmsTemplates /> },

	{ path: "/settings", component: <SettingLayout /> },
	{ path: "/payment", component: <Payment /> },

	{ path: "/calendar", component: <Calendar /> },
]

const adminPrivateRoutes = [
	// admin
	{ path: "/admin/users", component: <AdminUser /> },
	{ path: "/admin/poses", component: <AdminPos /> },
	{ path: "/admin/online", component: <AdminOnline /> },
	{ path: "/admin/payments-history", component: <AdminPayment /> },
	{ path: "/admin/duplicate", component: <AdminDuplicate /> },
	{ path: "/admin/sql", component: <AdminSql /> },
	{ path: "/admin/elements", component: <AdminElements /> },
	{ path: "/admin/permissions", component: <AdminPermissions /> },
]

const deliveryPrivateRoutes = [
	// delivery
	{ path: "/delivery/orders", component: <DeliveryOrders /> },
	{ path: "/delivery/create", component: <DeliveryCreate /> },
	{ path: "/delivery/cheques-rating", component: <ChequesRating /> },
]

const partnerPrivateRoutes = [
	// partner
	{ path: "/partner/dashboard", component: <PartnerDashboard /> },
	{ path: "/partner/poses", component: <PartnerPoses /> },
	{ path: "/partner/transactions", component: <PartnerPosesTransactions /> },
]

export {
	publicRoutes,
	privateRoutes,
	adminPrivateRoutes,
	deliveryPrivateRoutes,
	partnerPrivateRoutes
}