import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
//import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'

import { httpOk, GET } from 'helpers/api'
import { formatMoney, returnSign, formatDate } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Products() {
	const { t } = useTranslation()
	const date = new Date()

	const [totalAmount, setTotalAmont] = useState(0)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/report-gross-income',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/report-gross-income"
		var urlParams = ""
		checkFilter()

		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/report-gross-income${urlParams}` !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
		} else {
			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setData(response.data.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
				var totalIncome = 0
				response.data.forEach(item => {
					totalIncome += Number(item.income)
				});
				setTotalAmont(totalIncome)
			}
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await GET("/report-gross-income", {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)

			var totalIncome = 0
			response.data.data.forEach(item => {
				totalIncome += Number(item.income)
			});
			setTotalAmont(totalIncome)
		}
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.supplier_id === null &&
			filterData.category_id === null &&
			filterData.date_balance === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	useEffect(() => {
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('products')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="d-none">
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2" onClick={() => searchWithFilter(true)}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
						<div className="vertical-center">
							<h4>
								<b>{t('total_amount')}</b>: {formatMoney(totalAmount)}
							</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>#</th>
									<th>{t('name2')}</th>
									<th className="text-center">{t('quantity')}</th>
									<th className="text-center">{t('price')}</th>
									<th className="text-center">{t('sale_price')}</th>
									<th className="text-end">{t('profit')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-center">{item.quantity}</td>
										<td className="text-center">{formatMoney(item.price)}</td>
										<td className="text-center">{formatMoney(item.sale_price)}</td>
										<td className="text-end">{formatMoney(item.income)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

				</div>
			</div>
		</>
	)
}

export default Products