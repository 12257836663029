import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'; // eslint-disable-line no-unused-vars
//import io from 'socket.io-client'

import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import { DebounceInput } from 'react-debounce-input'
import DeleteModal from 'pages/parts/DeleteModal'

import { clearTemporaryStorage, formatDate, formatMoney, regexNumeric, regexPhoneNumber, returnSign } from 'helpers/helpers'
import { DELETE, GET, PUT, httpOk } from 'helpers/api'
import { SET_DELIVERY_DATA } from "store/delivery/actions"

// status -20 новый в cloud остался telegram bot
// status -15 новый в cloud остался call center
// status 0 не получен торговой точкой
// status 1 Готовиться
// status 2 Пора готовить
// status 3 Готовиться
// status 4 Приготовлено
// status 5 Подано
// status 8 Курьер выехал
// status 9 Курьер доставил и принял оплату
// status 10 успешно оплачен закрытый чек

function Orders() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const date = new Date()

	const echoRef = useRef(null)
	const posesRef = useRef([]);

	const reduxAccount = useSelector(state => state.account)
	const reduxDelivery = useSelector(state => state.delivery)

	const [data, setData] = useState([])
	const [allData, setAllData] = useState([])
	const [searchValue, setSearchValue] = useState('')
	const [poses, setPoses] = useState([])
	const [orderTypes, setOrderTypes] = useState({
		"order_type_1": 0,
		"order_type_2": 0,
		"order_type_delivery_1": 0,
		"order_type_delivery_2": 0,
		"order_type_delivery_3": 0,
	})
	// CLIENT UPDATE LOGIC
	const [client, setClient] = useState({})
	const [modalClient, setModalClient] = useState(false)
	// CLIENT UPDATE LOGIC
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [cheques, setCheques] = useState([]);
	const [modalCheques, setModalCheques] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
	})
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })

	function goTo(page) {
		if (page === -1) {
			dispatch({ type: 'USER_LOGGED_OUT', payload: null })
			clearTemporaryStorage()
			navigate('/auth/login')
			return
		} else {
			navigate('/delivery/create')
		}
	}

	async function deleteItem() {
		await DELETE(`/delivery/cheque/${deleteModalParams.id}`, {}, { loader: true })
		setModalDelete(false)
	}

	async function syncCheque(id) {
		await GET(`/delivery/sync/${id}`, {}, { loader: true })
	}

	function returnOnlineStatus(item) {
		if (item.online) {
			return { 'color': '#34c38f' };
		} else {
			return { 'color': '#f46a6a' };
		}
	}

	function search(value, response) {
		if (!value) {
			setData(allData)
			return;
		}

		var dataCopy = [...allData];
		if (response) dataCopy = [...response];

		const searchValue = value.toLowerCase();
		const filteredData = dataCopy.filter((item) => {
			return (
				item?.client_name?.toLowerCase().includes(searchValue) ||
				item?.client_phone1?.toLowerCase().includes(searchValue) ||
				item?.client_address?.toLowerCase().includes(searchValue) ||
				item?.total_amount_service?.toString().includes(searchValue)
			);
		});

		setData(filteredData)
		setSearchValue(searchValue)
	}

	// CLIENT UPDATE LOGIN
	async function toggleModalClient(bool, client_id) {
		if (bool && client_id) {
			var response = await GET(`/clients/${client_id}`, {}, { loader: true })
			if (httpOk(response)) {
				response = response.data
				if (response.phone1)
					response.phone1 = response.phone1.substring(3, 12)
				if (response.phone2)
					response.phone2 = response.phone2.substring(3, 12)
				setClient(response)
			}
		}

		setModalClient(bool)
	}

	async function updateClient(e) {
		e.preventDefault()
		var clientCopy = { ...client }
		if (clientCopy.phone2)
			clientCopy.phone2 = '998' + regexNumeric(clientCopy.phone2)

		var response = await PUT('/clients', clientCopy, { loader: true })
		if (httpOk(response)) {
			toggleModalClient(false)
		}
	}
	// CLIENT UPDATE LOGIN

	async function toggleModalCheques(bool = false) {
		setModalCheques(bool)

		if (bool) {
			var filterDataCopy = { ...filterData }
			var filterUrl = "/report-operators"
			var urlParams = ""

			if (filterDataCopy.start_date)
				urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
			if (filterDataCopy.end_date)
				urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
			filterUrl = filterUrl + urlParams

			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setCheques(response.data)
				setDropdownIsOpen(false)
			}
		} else {
			setCheques([])
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function returnStatusClass(status) {
		if (status === -10) {
			return "bg-danger text-white"
		}
		if (status === 0) {
			return "bg-danger text-white"
		}
		if (status === 1 || status === 2 || status === 3 || status === 4) {
			return "bg-primary text-white"
		}
		if (status === 5) {
			return "bg-primary text-white"
		}
		if (status === 7) {
			return "bg-primary text-white"
		}
		if (status === 8) {
			return "bg-primary text-white"
		}
		if (status === 9) {
			return "bg-success text-white"
		}
		if (status === 10) {
			return "bg-dark text-white"
		}
	}

	function returnStatus(status) {
		if (status === -11) {
			return t('not_paid')
		}
		if (status === -10) {
			return t('new')
		}
		if (status === 0) {
			return t('received')
		}
		if (status === 1 || status === 2 || status === 3 || status === 4 || status === 7) {
			return t('cooking')
		}
		if (status === 5) {
			return t('collected')
		}
		if (status === 8) {
			return t('on_the_way')
		}
		if (status === 9) {
			return t('paid')
		}
		if (status === 10) {
			return t('closed')
		}
	}

	async function connectSocket() {
		var wsHost;
		var authEndpoint;
		var isProd;

		if (process.env.NODE_ENV === 'production') {
			wsHost = process.env.REACT_APP_PROD_WSHOST
			authEndpoint = process.env.REACT_APP_PROD_BROADCAST_AUTH
			isProd = true
		} else {
			wsHost = process.env.REACT_APP_DEV_WSHOST
			authEndpoint = process.env.REACT_APP_DEV_BROADCAST_AUTH
			isProd = false
		}

		if (echoRef.current) return

		try {
			// const socket = io('http://127.0.0.1:6002', {
			// 	transports: ['websocket'],
			// });

			// socket.on('connect', () => {
			// 	console.log('Connected:', socket.connected); // Должно быть true
			// });

			// socket.on('connect_error', (err) => {
			// 	console.error('Connection Error:', err);
			// });

			echoRef.current = new Echo({
				broadcaster: 'pusher',
				key: "mison_key",
				wsHost: wsHost,
				wsPort: 6001,
				wssPort: 6001,
				enabledTransports: ['ws', 'wss'],
				disableStats: true,
				encrypted: isProd ? true : false,
				useTLS: isProd ? true : false,
				forceTLS: isProd ? true : false,
				authEndpoint: authEndpoint,
				auth: {
					headers: {
						'Authorization': `Bearer ${reduxAccount.token}`
					}
				},
			});
		} catch (error) {
			console.log(error)
		}

		echoRef.current.connector.pusher.connection.bind('connected', () => {
			console.log('connected private channel');
		});

		echoRef.current.connector.pusher.connection.bind('disconnected', () => {
			console.log('disconnected private channel');
		});

		echoRef.current.private(`call-center.${reduxAccount.parent}`)
			.listen('Cheques', (payload) => {
				console.log('payload: ', payload.data)

				var response = payload.data

				if (data.length !== response.length) {
					var posesCopy = posesRef.current
					for (let i = 0; i < posesCopy.length; i++) {
						posesCopy[i]['count'] = 0
						for (let j = 0; j < response.length; j++) {
							if (posesCopy[i]['id'] === response[j]['pos_id']) {
								posesCopy[i]['count'] += 1
							}
						}
					}

					setPoses(posesCopy)
					posesRef.current = posesCopy;
				}

				var orderTypesCopy = { ...orderTypes }
				for (let i = 0; i < response.length; i++) {
					if (response[i]['order_type'] === 2) orderTypesCopy.order_type_1 += 1
					if (response[i]['order_type'] === 3) orderTypesCopy.order_type_2 += 1
					if (response[i]['order_type_delivery'] === 1) orderTypesCopy.order_type_delivery_1 += 1
					if (response[i]['order_type_delivery'] === 2) orderTypesCopy.order_type_delivery_2 += 1
					if (response[i]['order_type_delivery'] === 3) orderTypesCopy.order_type_delivery_3 += 1
				}
				setOrderTypes(orderTypesCopy)
				if (searchValue) {
					search(searchValue, payload.data)
				} else {
					setData(payload.data)
				}
				setAllData(payload.data)
			});

		echoRef.current.join(`presence-call-center-${reduxAccount.parent}`)
			.here((users) => { // (users)
				users = users.filter(item => item.role !== "ROLE_CALL_CENTER")
				console.log('here', users)

				const posesCopy = posesRef.current.map(pos => {
					const updatedPos = { ...pos }; // Создаём новый объект
					updatedPos.online = users.some(user => user.pos_id === pos.id);
					return updatedPos;
				});

				setPoses(posesCopy); // Создаём новый массив
				posesRef.current = posesCopy;
			})
			.joining((user) => {
				console.log('joining', user)
				if (user.role === "ROLE_CALL_CENTER") { return }

				var posesCopy = [...reduxDelivery?.poses]
				for (let i = 0; i < posesCopy.length; i++) {
					if (posesCopy[i]['id'] === user.pos_id) {
						posesCopy[i]['online'] = true
						break;
					}
				}

				setPoses(posesCopy)
			})
			.leaving((user) => {
				console.log('leaving', user)
				if (user.role === "ROLE_CALL_CENTER") { return }

				var posesCopy = [...reduxDelivery?.poses]
				for (let i = 0; i < posesCopy.length; i++) {
					if (posesCopy[i]['id'] === user.pos_id) {
						posesCopy[i]['online'] = false
						break;
					}
				}

				setPoses(posesCopy)
			})
	}

	async function getData() {
		if (reduxDelivery?.menu?.length > 0) {
			posesRef.current = reduxDelivery?.poses;
			return
		}

		var response
		var poses = []
		var comments = []
		var paymentTypes = []
		var externalCouriers = []

		if (!reduxDelivery?.poses?.length) {
			poses = await GET('/helper/poses')
		}

		if (!reduxDelivery?.menu?.length) {
			response = await GET('/delivery/menu')
		}

		if (!reduxDelivery?.comments?.length) {
			comments = await GET('/helper/comments', {}, { 'loader': true })
		}

		if (!reduxDelivery?.externalCouriers?.length) {
			externalCouriers = await GET('/helper/external-couriers', {}, { 'loader': true })
		}

		if (reduxAccount?.call_center?.payment_types?.length) {
			paymentTypes = reduxAccount?.call_center?.payment_types
		}

		dispatch(SET_DELIVERY_DATA({
			'poses': poses.data,
			'menu': response?.data?.menu,
			'products': response?.data?.products,
			'comments': comments?.data,
			'paymentTypes': paymentTypes,
			'externalCouriers': externalCouriers?.data,
		}))

		setPoses(poses.data);
		posesRef.current = poses.data;
	}

	async function getCheques() {
		var response = await GET('/delivery/cheques', {}, { loader: true })
		var orderTypesCopy = { ...orderTypes }

		if (httpOk(response)) {
			response = response.data
			if (!posesRef.current) posesRef.current = reduxDelivery?.poses
			let posesCopy = [...posesRef.current]; // Берём актуальные данные poses
			for (let i = 0; i < posesCopy.length; i++) {
				posesCopy[i]['count'] = 0;
				for (let j = 0; j < response.length; j++) {
					if (posesCopy[i]['id'] === response[j]['pos_id']) {
						posesCopy[i]['count'] += 1;
					}
				}
			}

			setPoses(posesCopy); // Обновляем poses количеством заказов
			posesRef.current = posesCopy;

			for (let i = 0; i < response.length; i++) {
				if (response[i]['order_type'] === 2) orderTypesCopy.order_type_1 += 1
				if (response[i]['order_type'] === 3) orderTypesCopy.order_type_2 += 1
				if (response[i]['order_type_delivery'] === 1) orderTypesCopy.order_type_delivery_1 += 1
				if (response[i]['order_type_delivery'] === 2) orderTypesCopy.order_type_delivery_2 += 1
				if (response[i]['order_type_delivery'] === 3) orderTypesCopy.order_type_delivery_3 += 1
			}

			setData(response)
			setAllData(response)
			setOrderTypes(orderTypesCopy)
		}
	}

	async function initializeData() {
		await getData();
		await getCheques();

		connectSocket();
	}

	useEffect(() => {
		initializeData()

		return () => {
			// echoRef?.current?.leave(`call-center.${reduxAccount.parent}`)
			// echoRef?.current?.leave(`presence-call-center-${reduxAccount.parent}`)
			echoRef?.current?.disconnect()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>

			<div className="d-flex" style={{ 'height': 'calc(100vh - 50px)', paddingTop: '50px' }}>
				<div className="delivery-footer">
					<div className="d-flex">
						<div className="delivery-footer-item"
							onClick={() => goTo(-1)}>
							<span>{t('logout')}</span>
						</div>

						<div className="delivery-footer-item"
							onClick={() => {
								const newUrl = "/delivery/cheques-rating";
								window.open(newUrl, '_blank');
							}}>
							{t('check_evaluation')}
						</div>

						<div className="delivery-footer-item"
							onClick={() => toggleModalCheques(true)}>
							<span>{t('operators')}</span>
						</div>
					</div>

					<div className="delivery-footer-search">
						<i className="uil-search"></i>
						<DebounceInput
							type="text"
							value={searchValue}
							placeholder={t('search') + '...'}
							minLength={3}
							debounceTimeout={1000}
							onChange={(e) => search(e.target.value)}
							onKeyUp={(e) => {
								if (e.keyCode === 13) {
									search(e.target.value)
								}
							}}
						/>
					</div>

					<div className="d-flex align-items-center">
						<button className="delivery-footer-btn text-nowrap"
							onClick={() => goTo()}>
							<span>{t('new_order')}</span>
						</button>
					</div>
				</div>

				<div className="h-table-orders d-flex flex-column justify-content-between py-2 bg-white border-right"
					style={{ 'width': '15%' }}>
					<div className="px-2" style={{ 'height': 'calc(100vh - 250px)', 'overflow': 'auto' }}>
						<div className="d-flex justify-content-between pb-2">
							<h5>{t('poses')}:</h5>
							<h5>{formatMoney(data?.length)}</h5>
						</div>

						{poses?.map((item, index) => (
							<div key={index} className="d-flex flex-column gap-2">
								<div className="d-flex justify-content-between">
									<div style={{ 'width': '190px' }}>{item.name}</div>
									<div style={returnOnlineStatus(item)}>{formatMoney(item?.count)}</div>
								</div>
							</div>
						))}
					</div>
					<div className="px-2">
						<h5 className="d-flex justify-content-between mb-2">
							<span><i className="uil uil-arrow-up-right me-2"></i>{t('takeaway')}:</span>
							<span>{orderTypes.order_type_1}</span>
						</h5>
						<h5 className="d-flex justify-content-between mb-2">
							<span><i className="uil uil-truck me-2"></i>{t('delivery')}:</span>
							<span>{orderTypes.order_type_2}</span>
						</h5>
						<hr />
						<h5 className="d-flex justify-content-between mb-2">
							<span><i className="uil uil-telegram me-2"></i>{t('telegram')}:</span>
							<span>{orderTypes.order_type_delivery_2}</span>
						</h5>
						<h5 className="d-flex justify-content-between mb-2">
							<span><i className="uil uil-headphones me-2"></i> {t('call_center')}:</span>
							<span>{orderTypes.order_type_delivery_3}</span>
						</h5>
						<h5 className="d-flex justify-content-between">
							<span><i className="uil uil-shop me-2"></i> {t('pos')}:</span>
							<span>{orderTypes.order_type_delivery_1}</span>
						</h5>
					</div>
				</div>
				<div style={{ 'width': '85%' }}>
					<div className="table-responsive h-table-orders table-fixed"
						style={{ 'background': '#fff' }}>
						<table className="table delivery-table mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '3%' }} className="text-center">#</th>
									<th style={{ 'width': '7%' }} className="text-center">{t('time')}</th>
									<th style={{ 'width': '20%' }} className="text-center">{t('address')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('courier')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('client')}</th>
									<th style={{ 'width': '10%' }} className="text-center td-ellipsis">{t('pos')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('comment')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('total')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('action')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<Fragment key={index}>
										<tr>
											<td className="text-center">{index + 1}</td>
											<td className="text-center bg-4">
												<div className="fz-20">
													<b>{formatDate(item.cheque_opened_at, 'HH:mm')}</b>
												</div>
											</td>
											<td className="text-center delivery-address">
												<div>{item.client_address}</div>
												<div>{item.navigate}</div>
											</td>
											<td className="text-center">
												<div className="d-flex flex-column">
													{!!item.courier_id &&
														<>
															<div>{item.courier_name}</div>
															<div>{regexPhoneNumber(item.courier_phone)}</div>
														</>
													}
													{!!item.delivery_distance &&
														<div>{t('distance')} {item.delivery_distance}km</div>
													}
												</div>
											</td>
											<td>
												<div className="text-center">
													<div className="d-flex flex-column gap-2">
														<div className="fz-16 custom-truncate text-hover"
															style={{ 'width': '180px' }}
															onClick={() => toggleModalClient(true, item.client_id)}>
															{item.client_name}
														</div>
														<div>{regexPhoneNumber(item.client_phone1)}</div>
													</div>
												</div>
											</td>
											<td className="text-center">
												<div>{item.pos_name ?? ''}</div>
												<div>{regexPhoneNumber(item.pos_phone)}</div>
												<div>{(item.cashier_first_name ?? '') + ' ' + (item.cashier_last_name ?? '')}</div>
											</td>
											<td className="text-center">{item.note}</td>
											<td>
												<div className="fz-20 text-center">
													<b>{formatMoney(item.total_amount_service)}</b>
												</div>
											</td>
											<td>
												<div className="d-flex justify-content-center gap-2">
													{item.status === -10 &&
														<div className="table-action-button table-action-success-button"
															onClick={() => syncCheque(item.id)}>
															<i className="uil-refresh"></i>
														</div>
													}
													{(
														(
															item.status === -10 ||
															item.status === 0
														) && item.order_type_delivery === 3) &&
														<div className="table-action-button table-action-danger-button"
															onClick={() => {
																setDeleteModalParams({ id: item.id, index: index });
																setModalDelete(true)
															}}>
															<i className="uil-times"></i>
														</div>
													}
												</div>
											</td>
										</tr>
										<tr className="bottom-tr">
											<td className="text-center bg-1">
												{item.order_type_delivery === 1 &&
													<i className="uil uil-shop"></i>
												}
												{item.order_type_delivery === 2 &&
													<i className="uil uil-telegram"></i>
												}
												{item.order_type_delivery === 3 &&
													<i className="uil uil-headphones"></i>
												}
											</td>
											<td className="text-center bg-1">
												ID: {item.id}
											</td>
											<td colSpan={6} className="bg-2">
												<div className="d-flex flex-wrap gap-1 ">
													{item?.products?.length &&
														<>
															{item?.products?.map((product, productIndex) => (
																<span key={productIndex}>
																	{product.name}(x{product.quantity}),
																</span>
															))}
														</>
													}
												</div>
											</td>
											<td colSpan={1} className={`text-center ${returnStatusClass(item.status)}`}>
												{returnStatus(item.status)}
											</td>
										</tr>
									</Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div >

			{/* MODAL UPDATE CLIENT */}
			<Modal show={modalClient} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModalClient(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('edit')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => updateClient(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone1"
									disabled
									value={client.phone1 ?? ''}
									onChange={() => { }} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('name')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="first_name"
								value={client.first_name ?? ''}
								onChange={(e) => setClient({ ...client, 'first_name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('additional_phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone2"
									value={client.phone2 ?? ''}
									onChange={(e) => setClient({ ...client, 'phone2': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={client.note ?? ''}
								onChange={(e) => setClient({ ...client, 'note': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('navigate')}</label>
							<input type="text" className="form-control" name="navigate"
								value={client.navigate ?? ''}
								onChange={(e) => setClient({ ...client, 'navigate': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalClient(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL UPDATE CLIENT */}

			{/* MODAL CHEQUES */}
			<Modal show={modalCheques} animation={false} centered size="xl"
				dialogClassName="update-modal-width"
				onHide={() => toggleModalCheques(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('operators')} [{cheques?.data?.length}]</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-2">
						<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
							<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
								{t('filter')}
							</Dropdown.Toggle>

							<Dropdown.Menu className="dropdown-filter">
								<div className="row">
									<div className="form-group col-md-6">
										<label>{t('period')}</label>
										<div className="calendar-input">
											<DatePicker
												className="form-control"
												dateFormat="dd.MM.yyyy"
												selected={filterData.start_date ? new Date(filterData.start_date) : ''}
												onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
											<i className="uil uil-calendar-alt"></i>
										</div>
									</div>
									<div className="form-group col-md-6">
										<label className="invisible">.</label>
										<div className="calendar-input">
											<DatePicker
												className="form-control"
												dateFormat="dd.MM.yyyy"
												selected={filterData.end_date ? new Date(filterData.end_date) : ''}
												onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
											<i className="uil uil-calendar-alt"></i>
										</div>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-12">
										<div className="d-flex justify-content-end gap-2">
											<button className="btn btn-secondary"
												onClick={() => setDropdownIsOpen(false)}>
												{t('cancel')}
											</button>
											<button className="btn btn-primary"
												onClick={() => toggleModalCheques(true)}>
												{t('apply')}
											</button>
										</div>
									</div>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>

					<div className="filter-block">
						<div className="filter">
							<strong>{t('filter')}</strong>
							<br />
							{(filterData.start_date || filterData.end_date) &&
								<span className="me-2">
									<b>{t('period')}: </b>
									{filterData.start_date &&
										<span>{formatDate(filterData.start_date)}</span>
									}
									{(filterData.start_date && filterData.end_date) &&
										<span>|</span>
									}
									{filterData.end_date &&
										<span>{formatDate(filterData.end_date)}</span>
									}
								</span>
							}
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }} className="td-ellipsis">{t('operators')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('products')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end"
										title={t('average_products_per_cheque')}>
										{t('average_products_per_cheque')}
									</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('average_check')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('takeaway')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('delivery')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('cheques')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{cheques?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td className="td-ellipsis">{item.operator_name}</td>
										<td className="text-end">{formatMoney(item.total_products)}</td>
										<td className="text-end">{formatMoney(item.avg_products_per_cheque, 2)}</td>
										<td className="text-end">{formatMoney(item.avg_total_amount)}</td>
										<td className="text-end">{formatMoney(item.count_takeaway)}</td>
										<td className="text-end">{formatMoney(item.count_delivery)}</td>
										<td className="text-end">{formatMoney(item.count_cheques)}</td>
										<td className="text-end">{formatMoney(item.total_amount)}</td>
									</tr>
								))}
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td className="text-end"><b>{formatMoney(cheques?.overall?.overall_products)}</b></td>
									<td></td>
									<td></td>
									<td className="text-end"><b>{formatMoney(cheques?.overall?.overall_takeaway)}</b></td>
									<td className="text-end"><b>{formatMoney(cheques?.overall?.overall_delivery)}</b></td>
									<td className="text-end"><b>{formatMoney(cheques?.overall?.overall_cheques)}</b></td>
									<td className="text-end"><b>{formatMoney(cheques?.overall?.overall_cheques_amount)}</b></td>
								</tr>
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL CHEQUES */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Orders